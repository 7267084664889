<template>
  <div>
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <span><i class="icon-briefcase mr-05"></i> Détails</span>
        <button
          v-if="userHasWritePermission"
          type="button"
          class="btn btn-danger btn-sm"
          @click="showDeleteModal = true"
        >
          <i class="fa fa-trash"></i> Supprimer
        </button>
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Logo</dt>
          <dd class="col-sm-9">
            <template v-if="!noImg">
              <img
                :src="getImg"
                style="max-width: 250px"
                @error="noImg = true"
              />

              <div v-if="userHasWritePermission" class="d-flex mt-1">
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="noImg = true"
                >
                  <i class="fa fa-pencil"></i> Modifier
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm ml-05"
                  @click="deleteImg"
                >
                  <i class="fa fa-trash"></i> Supprimer
                </button>
              </div>
            </template>
            <dropzone
              v-else-if="userHasWritePermission"
              :url="apiUploads()"
              :headers="apiAuthorization()"
              id="photo"
              v-on:vdropzone-success="imgUploaded"
            >
              <div class="dz-default dz-message">
                <span>
                  <i class="icon-cloud-upload"></i><br />Glissez l'image ici
                </span>
              </div>
            </dropzone>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Nom</dt>
          <dd class="col-sm-9">
            <el-input
              v-model="companyData.name"
              size="mini"
              @change="editCompany({ name: $event })"
              v-if="userHasWritePermission"
            />
            <span v-else>{{ companyData.name }}</span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Description</dt>
          <dd class="col-sm-9">
            <textarea
              v-if="userHasWritePermission"
              id="note"
              name="note"
              class="form-control"
              v-model="companyData.description"
              @change="editCompany({ description: $event.target.value })"
            ></textarea>
            <span v-else>
              {{ companyData.description }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Client référent</dt>
          <dd class="col-sm-9">
            <multiselect
              v-if="userHasWritePermission"
              v-model="currentReferent"
              :options="companyClients"
              :multiple="false"
              :allow-empty="false"
              @input="editCompany({ referentClientEmail: $event.value })"
              label="label"
              track-by="label"
            >
            </multiselect>
            <span v-else-if="currentReferent">
              <a @click="goToClient(currentReferent.id)">{{
                currentReferent.label
              }}</a>
            </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Statut</dt>
          <dd class="col-sm-9">
            <multiselect
              v-if="userHasWritePermission"
              :value="currentStatut"
              :options="companyStatuts"
              :multiple="false"
              :allow-empty="false"
              @input="openStatusConfirm($event)"
              label="label"
              track-by="label"
            >
            </multiselect>
            <span v-else-if="currentStatut">{{ currentStatut.label }}</span>
          </dd>
        </dl>
        <dl class="row" v-if="companyData.statut === 'lost'">
          <dt class="col-sm-3">Raison de la perte</dt>
          <dd class="col-sm-9">
            <multiselect
              v-if="userHasWritePermission"
              v-model="currentLostReason"
              :options="globalLostReasons"
              :multiple="false"
              :allow-empty="false"
              @input="editCompany({ lostReason: $event.value })"
              label="label"
              track-by="label"
            >
            </multiselect>
            <span v-else-if="currentLostReason" class="ml-2">{{
              currentLostReason.label
            }}</span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Note</dt>
          <dd class="col-sm-9">
            <textarea
              id="note"
              name="note"
              class="form-control"
              v-model="companyData.note"
              @change="editCompany({ note: $event.target.value })"
              v-if="userHasWritePermission"
            ></textarea>
            <span v-else>
              {{ companyData.note }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Domaines</dt>
          <dd class="col-sm-9">
            <div v-if="companyData.domains && companyData.domains.length">
              <span
                v-for="domain in companyData.domains"
                :key="domain"
                class="mb-1 mr-1 badge badge-primary"
                style="cursor: pointer"
                @click="editDomain(domain)"
              >
                {{ domain }}
                <span
                  v-if="userHasWritePermission"
                  class="ml-05"
                  @click.stop="removeDomain(domain)"
                >
                  <i class="fa fa-times"></i>
                </span>
              </span>
            </div>

            <template v-if="addDomain.showInput">
              <div class="d-flex mb-1">
                <el-input v-model="addDomain.domainName" size="mini" />
                <button
                  class="btn btn-sm btn-primary ml-05"
                  @click="addDomainName"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <button
                  class="btn btn-sm btn-danger ml-05"
                  @click="cancelAddDomain"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <div
                class="alert alert-danger mb-1"
                role="alert"
                v-if="addDomain.domainError"
              >
                {{ addDomain.domainError }}
              </div>
            </template>
            <div v-else-if="userHasWritePermission">
              <button class="btn btn-sm" @click="addDomain.showInput = true">
                <i class="fa fa-plus"></i> Ajouter un domaine
              </button>
            </div>
          </dd>
        </dl>
      </div>
    </div>

    <CompanyStatusModal
      :statusConfirmModal="statusConfirmModal"
      @update="({ statut, lostReason }) => editCompany({ statut, lostReason })"
    />
    <modal
      title="Supprimer l'entreprise"
      v-model="showDeleteModal"
      effect="fade/zoom"
    >
      <div slot="modal-body" class="modal-body">
        <div class="alert alert-danger" role="alert">
          Attention ! Supprimer cette société est irréversible, cela supprimera
          également le lien avec les fiches clients. Êtes-vous sûr de vouloir
          continuer ?
        </div>
      </div>
      <div
        slot="modal-footer"
        class="modal-footer d-flex justify-content-between"
      >
        <button
          type="button"
          class="btn btn-default mr-1"
          @click="showDeleteModal = false"
        >
          Annuler
        </button>
        <button type="button" class="btn btn-danger" @click="deleteCompany">
          Oui, je confirme
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Dropzone from "vue2-dropzone";
import Modal from "vue-strap/src/Modal";
import CompanyStatusModal from "./CompanyStatusModal";

export default {
  components: {
    Multiselect,
    Dropzone,
    Modal,
    CompanyStatusModal,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      companyData: {},
      noImg: false,
      timestamp: Date.now(),
      companyClients: [],
      companyDomains: [],
      currentReferent: null,
      currentStatut: null,
      currentLostReason: null,
      showDeleteModal: false,
      statusConfirmModal: {
        show: false,
        statut: null,
      },
      addDomain: {
        showInput: false,
        domainName: "",
        editingDomain: null,
        domainError: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      companyStatuts: "getCompanyStatuts",
      lostReasons: "getLostReasons",
      companyLost: "getCompanyLost",
    }),

    globalLostReasons() {
      return this.companyLost.concat(this.lostReasons);
    },

    getImg() {
      return `${axios.defaults.baseURL}companies/${this.company.id}/pictures?timestamp=${this.timestamp}`;
    },

    userHasWritePermission() {
      return this.$route.name === "public-procurement-details"
        ? this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          )
        : this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE");
    },
  },

  created() {
    this.companyData = this.company;

    this.companyClients = [
      ...this.company.clients.map((client) => {
        const label =
          client.firstName && client.lastName
            ? `${client.firstName} ${client.lastName}`
            : client.email;

        return {
          label,
          value: client.email,
          id: client.id,
        };
      }),
    ];

    this.companyDomains = this.company.domains ? [...this.company.domains] : [];

    if (this.company.referentClientEmail) {
      this.currentReferent = this.companyClients.find(
        (client) => client.value === this.company.referentClientEmail
      );
    }

    this.currentStatut = this.companyStatuts.find(
      (statut) => statut.value === this.company.statut
    );

    this.currentLostReason = this.globalLostReasons.find(
      (lostReason) => lostReason.value === this.company.lostReason
    );
  },

  watch: {
    company: {
      handler() {
        if (this.company.img !== this.companyData.img) {
          this.noImg = !this.company.img;

          // Force image reload
          this.timestamp = Date.now();
        }

        this.companyData = this.company;

        this.companyClients = [
          ...this.company.clients.map((client) => {
            const label =
              client.firstName && client.lastName
                ? `${client.firstName} ${client.lastName}`
                : client.email;

            return {
              label,
              value: client.email,
              id: client.id,
            };
          }),
        ];

        this.companyDomains = this.company.domains
          ? [...this.company.domains]
          : [];

        if (this.company.referentClientEmail) {
          this.currentReferent = this.companyClients.find(
            (client) => client.value === this.company.referentClientEmail
          );
        }

        this.currentStatut = this.companyStatuts.find(
          (statut) => statut.value === this.company.statut
        );

        this.currentLostReason = this.globalLostReasons.find(
          (lostReason) => lostReason.value === this.company.lostReason
        );
      },
      deep: true,
    },
  },

  methods: {
    apiUploads() {
      return `${axios.defaults.baseURL}uploads`;
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    editCompany(data) {
      this.$emit("edit", data);
    },

    deleteCompany() {
      this.$emit("delete");
    },

    async imgUploaded(file, response) {
      this.editCompany({ img: response.id });
    },

    goToClient(clientId) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id: clientId,
        },
      });

      window.open(route.href, "_blank");
    },

    openStatusConfirm({ value }) {
      if (value) {
        this.statusConfirmModal.show = true;
        this.statusConfirmModal.statut = value;
      }
    },

    deleteImg() {
      this.$confirm({
        message: "Voulez-vous vraiment supprimer l'image ?",
        onConfirm: () => {
          this.editCompany({ img: null });
        },
      });
    },

    addDomainName() {
      this.addDomain.domainError = null;

      if (!this.addDomain.domainName || !this.addDomain.domainName.trim()) {
        this.addDomain.domainError = "Veuillez renseigner un nom de domaine";

        return;
      }

      const domain = this.addDomain.domainName.trim();

      if (
        domain.length <= 3 ||
        !domain.includes(".") ||
        domain.endsWith(".") ||
        (domain.match(/\W/g) &&
          !domain.match(/\W/g).every((char) => char === "." || char === "-"))
      ) {
        this.addDomain.domainError = "Nom de domaine invalide";

        return;
      }

      if (this.companyDomains.includes(domain)) {
        this.addDomain.domainError = "Nom de domaine déjà existant";

        return;
      }

      this.editCompany({ domains: [...this.companyDomains, domain] });

      this.addDomain.showInput = false;
      this.addDomain.domainName = "";
      this.addDomain.editingDomain = null;
    },

    cancelAddDomain() {
      this.addDomain.showInput = false;
      this.addDomain.domainError = null;
      this.addDomain.domainName = "";

      if (
        this.addDomain.editingDomain &&
        !this.companyDomains.includes(this.addDomain.editingDomain)
      ) {
        this.companyDomains.push(this.addDomain.editingDomain);
      }

      this.addDomain.editingDomain = null;
    },

    editDomain(domain) {
      if (!this.userHasWritePermission) {
        return;
      }

      this.companyDomains = this.companyDomains.filter((d) => d !== domain);
      this.addDomain.editingDomain = domain;
      this.addDomain.domainName = domain;
      this.addDomain.showInput = true;
    },

    removeDomain(domain) {
      const clientsWithDomain = this.company.clients.filter(
        (client) => client.email.split("@")[1] === domain
      );

      if (clientsWithDomain.length > 0) {
        this.$confirm({
          message: `Attention, ${clientsWithDomain.length} client(s) utilise(nt) ce nom de domaine. Êtes-vous sûr de vouloir le supprimer ?`,
          onConfirm: () => {
            this.editCompany({
              domains: this.companyDomains.filter((d) => d !== domain),
            });
          },
        });
      } else {
        this.editCompany({
          domains: this.companyDomains.filter((d) => d !== domain),
        });
      }
    },
  },
};
</script>
