<template>
  <modal
    :title="form.id ? 'Éditer' : 'Ajouter'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="form.id ? 'Éditer' : 'Ajouter'"
    @cancel="() => closeModal()"
    @ok="form.id ? handleEditCourse() : handleAddCourse()"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" text="Création du cours et des sessions" />
    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">{{ form.id ? "Éditer" : "Ajouter" }}</h4>
    </div>
    <div v-if="formError" class="alert alert-danger">{{ formError }}</div>
    <div class="form-group">
      <label>Nom *</label>
      <router-link
        target="_blank"
        :to="'/live/live-origins'"
        class="pull-right"
      >
        Ajouter un cours
      </router-link>
      <select
        v-model="form.origin"
        class="form-control"
        @change="(e) => onOriginChange(e.target.value)"
      >
        <option v-for="{ id, name } in origins" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Photo</label>
      <a
        v-if="form.id && form.photoId"
        class="pull-right"
        target="_blank"
        :href="`${apiUrl}lives/${form.id}/photo`"
        >Voir l'image</a
      >
      <a
        v-else-if="form.origin"
        class="pull-right"
        target="_blank"
        :href="getPictureImage(form.origin)"
        >Voir l'image</a
      >
      <dropzone
        :url="apiUploads()"
        :headers="apiAuthorization()"
        id="photo"
        v-on:vdropzone-success="photoUploaded"
      >
        <div class="dz-default dz-message">
          <span
            ><i class="icon-cloud-upload"></i><br />Glissez l'image ici</span
          >
        </div>
      </dropzone>
    </div>

    <div class="form-group">
      <label>Description</label>
      <textarea v-model="form.description" class="form-control" />
    </div>

    <div class="form-group">
      <label>Couleur Calendrier</label>
      <div>
        <el-color-picker v-model="form.colorCalendar"></el-color-picker>
      </div>
    </div>

    <div class="form-group">
      <label>Niveau *</label>
      <select v-model="form.lvl" class="form-control">
        <option v-for="{ value, label } in lvls" :key="value" :value="value">
          {{ label }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label> Durée du cours (minutes) </label>
      <input v-model="form.duration" type="number" class="form-control" />
    </div>

    <div class="form-group">
      <label>Date *</label>
      <div style="datepicker-ctn">
        <p v-show="!isUniqueDate">Chaque {{ selectedDay }}</p>
        <div class="d-inline">
          <el-date-picker
            v-model="startDate"
            type="date"
            placeholder="Date de début"
            :picker-options="pickerOptions0"
            :disabled="startDateDisabled"
            format="dd/MM/yyyy"
            @input="updateStartDate"
          >
          </el-date-picker>
        </div>
        <div v-if="!isUniqueDate" class="d-inline">
          <span>au</span>
          <el-date-picker
            v-model="endDate"
            type="date"
            placeholder="Date de fin"
            :picker-options="pickerOptions1"
            :disabled="endDateDisabled"
            format="dd/MM/yyyy"
          >
          </el-date-picker>
          inclus
        </div>
      </div>
      <el-checkbox v-model="isUniqueDate">Date unique</el-checkbox>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Heure *</label>
          <select class="form-control" v-model="form.hour">
            <option v-for="hour in 22" :key="hour" :value="hour - 1">
              {{ 10 > hour - 1 ? `0${hour - 1}` : hour - 1 }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Minutes *</label>
          <select class="form-control" v-model="form.minutes">
            <option v-for="min in 60" :key="min - 1" :value="min - 1">
              {{ 10 > min - 1 ? `0${min - 1}` : min - 1 }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Type de live</label>
      <select v-model="form.type" class="form-control">
        <option v-for="type in types" :key="type.value" :value="type.value">
          {{ type.label }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label> Quotas de personne </label>
      <input v-model="form.quotas" type="number" class="form-control" />
    </div>

    <div class="form-group">
      <label>Réservé a une entreprise</label>
      <select v-model="form.companyId" class="form-control">
        <option v-for="{ id, name } in companies" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "vue-strap/src/Modal";
import Dropzone from "vue2-dropzone";
import Loader from "../../components/Loader";

const TYPES = [
  {
    label: "Mode live",
    value: "live",
  },
  {
    label: "Mode Visio",
    value: "small",
  },
];
const LEVELS = [
  {
    label: "Tous niveaux",
    value: undefined,
  },
  {
    label: "Débutant",
    value: 0,
  },
  {
    label: "Intermédiaire",
    value: 1,
  },
  {
    label: "Confirmé",
    value: 2,
  },
];

export default {
  components: {
    Modal,
    Dropzone,
    Loader,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    course: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    form: {
      id: null,
      origin: null,
      photoId: null,
      description: null,
      colorCalendar: null,
      lvl: null,
      duration: null,
      type: null,
      date: [null, null],
      hour: null,
      minutes: null,
      quotas: null,
      companyId: null,
    },
    modalOpened: false,
    loading: false,
    formError: false,
    types: TYPES,
    lvls: LEVELS,
    companies: [],
    origins: [],
    selectedDay: "semaine du",
    endDateDisabled: true,
    startDate: null,
    endDate: null,
    isUniqueDate: false,
    startDateDisabled: false,
  }),
  mounted() {
    this.form = { ...this.course };
    this.modalOpened = this.modalShow;

    this.getOrigins();
    this.fetchCompanies();
  },
  methods: {
    async getOrigins() {
      const { data } = await this.$api.get("/live-origins");
      this.origins = data;
    },

    async fetchCompanies() {
      try {
        const { data } = await this.$api.get("/companies/simples");

        this.companies = data;
      } catch (e) {
        this.companies = [];
      }
    },

    async onOriginChange(originId) {
      const { data } = await this.$api.get(`/live-origins/${originId}`);
      delete data.id;
      this.form = { ...this.form, ...data };
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    photoUploaded(file, response) {
      this.form.photoId = response.id;
    },

    async handleAddCourse() {
      if (this.loading) {
        return false;
      }

      this.form.date = [this.startDate, this.endDate];

      if (!this.form.origin || !this.form.date || !this.form.hour) {
        this.formError = `Les champs Nom, Date et Heure sont obligatoires`;
        return false;
      }

      this.loading = true;

      try {
        await this.$api.post("/lives", this.form);

        this.closeModal();
      } catch (e) {
        this.formError = `Une erreur s'est produite`;
      }

      this.loading = false;
    },

    async handleEditCourse() {
      this.loading = true;
      this.form.date = [this.startDate, this.endDate];
      await this.$api.patch(`/lives/${this.form.id}`, this.form);
      this.closeModal();
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;
      this.form = {};
      this.$emit("close");
    },

    updateStartDate() {
      if (this.startDate) {
        this.endDateDisabled = false;
        switch (new Date(this.startDate).getDay()) {
          case 0:
            this.selectedDay = "dimanche du";
            break;
          case 1:
            this.selectedDay = "lundi du";
            break;
          case 2:
            this.selectedDay = "mardi du";
            break;
          case 3:
            this.selectedDay = "mercredi du";
            break;
          case 4:
            this.selectedDay = "jeudi du";
            break;
          case 5:
            this.selectedDay = "vendredi du";
            break;
          case 6:
            this.selectedDay = "samedi du";
            break;
          default:
            this.selectedDay = "semaine du";
            this.endDateDisabled = true;
            break;
        }
      } else {
        this.selectedDay = "semaine du";
        this.endDateDisabled = true;
      }
    },

    getPictureImage(id) {
      return `${process.env.VUE_APP_API_URL}live-origins/${id}/photo`;
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL || "";
    },

    pickerOptions0() {
      return {
        firstDayOfWeek: 1,
      };
    },

    pickerOptions1() {
      return {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.startDate);
        },
      };
    },
  },
  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
        if (newVal) {
          this.form = this.course;
          this.form.origin = this.course.originId;
          this.startDate = this.course.date;
          this.endDate = this.course.endDate;
          this.updateStartDate();
          if (this.course.date && this.course.date.length > 0) {
            this.startDateDisabled = true;
          } else {
            this.startDateDisabled = false;
          }
        }
      }
    },

    isUniqueDate: {
      handler: function () {
        if (this.isUniqueDate) {
          this.endDate = this.startDate;
        }
      },
    },

    startDate: {
      handler: function () {
        if (this.isUniqueDate) {
          this.endDate = this.startDate;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.datepicker-ctn {
  width: 100%;
  .custom-datepicker {
    border-radius: 0px;
    &.el-date-editor {
      width: 100%;
    }
  }
}
.form-group .el-date-editor .el-range-separator {
  width: auto;
  min-width: 5%;
}
</style>
