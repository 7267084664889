var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('router-view'),_c('loader',{attrs:{"isVisible":_vm.isLoading}}),(
      ['company-details', 'public-procurement-details'].indexOf(_vm.$route.name) >
        -1 &&
      !_vm.isLoading &&
      !!_vm.company
    )?_c('div',{staticClass:"animated fadeIn"},[_c('h1',{staticClass:"h3 px-1"},[_vm._v(_vm._s(_vm.company.name))]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('CompanyDetailsCard',{attrs:{"company":_vm.company},on:{"edit":_vm.editCompany,"delete":_vm.deleteCompany}}),_c('CompanyFeaturesCard',{attrs:{"company":_vm.company}})],1),_c('div',{staticClass:"col-md-6"},[_c('CompanyPublicProcurementToggleCard',{attrs:{"company":_vm.company}}),_c('CompanyCommercial',{attrs:{"company":_vm.company,"card":""}}),_c('CompanyListClientCard',{attrs:{"company":_vm.company},on:{"linked":_vm.clientLinked,"unlinked":_vm.clientUnlinked}}),(_vm.company.id)?_c('CompanyLocalisationsCard',{attrs:{"companyLocalisations":_vm.company.localisations,"companyId":_vm.company.id}}):_vm._e()],1)]),_vm._m(0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"})])
}]

export { render, staticRenderFns }