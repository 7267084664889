<template>
  <modal
    title="Lieu d'intervention"
    v-model="isVisible"
    @cancel="closeModal"
    effect="fade/zoom"
  >
    <div slot="modal-body" class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <label for="name">Nom</label>
          <el-input id="name" v-model="name" size="mini" />
        </div>
        <div class="col-sm-12 mt-2">
          <label for="geoloc">Lieu d'intervention</label>
          <Geoloc
            id="geoloc"
            :geoloc="geoloc"
            :header="null"
            :address="formatAddress(form)"
            :displayAddress="true"
            placeholder="Lieu d'intervention"
            @placeChanged="handleGmapInput"
            :disabled="!userHasWritePermission"
          />
        </div>

        <div v-if="errors.length" class="col-sm-12">
          <div class="alert alert-danger mb-0">
            <ul style="margin-bottom: 0">
              <li
                v-for="(error, index) in errors"
                :key="`localisation-form-error-${index}`"
              >
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <button type="button" class="btn btn-default" @click="closeModal">
        Fermer
      </button>
      <button
        v-if="userHasWritePermission"
        type="button"
        class="btn btn-primary"
        @click="submit"
      >
        {{ form.id ? "Modifier" : "Ajouter" }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Geoloc from "../Geoloc";

export default {
  components: {
    Modal,
    Geoloc,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    companyLocalisation: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isVisible: false,
      name: "",
      form: {
        id: undefined,
        localisationId: undefined,
        address: "",
        zipcode: "",
        city: "",
        country: "France",
      },

      geoloc: undefined,

      errors: [],
    };
  },

  computed: {
    userHasWritePermission() {
      return this.$route.name === "public-procurement-details"
        ? this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          )
        : this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE");
    },
  },

  watch: {
    companyLocalisation(companyLoc) {
      if (companyLoc) {
        this.form = {
          id: companyLoc.id,
          localisationId: companyLoc.localisationId,
          address: companyLoc.localisation.address,
          zipcode: companyLoc.localisation.zipcode,
          city: companyLoc.localisation.city,
          country: companyLoc.localisation.country,
        };

        const { latitude, longitude } = companyLoc.localisation.geoloc;
        this.geoloc = { lat: latitude, lng: longitude };
        this.name = companyLoc.name;
      } else {
        this.reinitializeData();
      }
    },

    show(value) {
      this.isVisible = value;
    },
  },

  methods: {
    submit() {
      this.checkFields();

      if (this.errors.length) return;

      if (this.form.id) {
        this.$emit("edit", { ...this.form, name: this.name });
      } else {
        this.$emit("add", { ...this.form, name: this.name });
      }
    },

    checkFields() {
      this.errors = [];

      if (!this.form.address) {
        this.errors.push("L'adresse est obligatoire");
      }

      if (!this.form.zipcode) {
        this.errors.push("Le code postal est obligatoire");
      }

      if (!this.form.city) {
        this.errors.push("La ville est obligatoire");
      }
    },

    reinitializeData() {
      this.form = {
        id: undefined,
        localisationId: undefined,
        address: "",
        zipcode: "",
        city: "",
        country: "France",
      };
      this.geoloc = undefined;
    },

    closeModal() {
      this.$emit("close");
      this.reinitializeData();
    },

    handleGmapInput(data) {
      const { address, city, zipcode, country, geoloc } = data;

      this.form = {
        ...this.form,
        address,
        city,
        zipcode,
        country,
      };

      this.geoloc = { lat: geoloc[1], lng: geoloc[0] };
    },
  },
};
</script>
