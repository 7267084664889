<template>
  <footer class="app-footer">
    Just Coaching &copy; 2017 -
    {{ new Date().getFullYear() }}
    : v.1.23.0 - 13 Janvier 2025
  </footer>
</template>

<script>
export default {};
</script>
