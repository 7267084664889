<template>
  <div class="order-custom-wrapper">
    <el-table class="table-condensed" :data="orders">
      <el-table-column v-if="showStartRowIcons" width="34">
        <template slot-scope="scope">
          <i
            v-if="orderHasFailedPayment(scope.row)"
            class="text-danger fa fa-exclamation-circle"
          />
          <i v-if="scope.row.giftCard" class="text-primary fa fa-gift" />
          <i
            v-if="scope.row.sponsorship || scope.row.usedSponsorship"
            class="text-primary fa fa-bullhorn"
          ></i>
        </template>
      </el-table-column>

      <el-table-column width="30">
        <template slot-scope="scope">
          <div v-html="conceptLogo(scope.row.concept, concepts)" />
        </template>
      </el-table-column>

      <el-table-column label="Date" prop="createdAt" width="100">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="client"
        label="Prénom"
        prop="refClient.firstName"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.refClient.company"
            ><strong>{{ scope.row.refClient.company }}</strong></span
          >
          <span v-else>{{ scope.row.refClient.firstName }}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="client"
        label="Nom"
        prop="refClient.lastName"
        min-width="120"
      >
        <template slot-scope="scope">
          <div v-if="!scope.row.refClient.company">
            <span>
              {{ scope.row.refClient.lastName }}
            </span>
            <i
              class="text-primary icon-user"
              style="margin-left: 0.5rem"
              @click="openParticipantsTabs(scope.row)"
            ></i>
            <span
              v-if="
                scope.row.orderGroup &&
                scope.row.orderGroup.orders &&
                scope.row.orderGroup.orders.length > 1
              "
            >
              {{ scope.row.orderGroup.orders.length }}
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Pack" prop="session" width="110">
        <template slot-scope="scope">
          <a @click="(e) => goToOrder(e, scope.row)" href="#">
            {{ formatPack(scope.row.session) || "#" }}
          </a>
        </template>
      </el-table-column>

      <el-table-column v-if="!client" label="Etat" prop="sessions" width="100">
        <template slot-scope="scope">
          {{ orderState(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column label="Montant" prop="price" width="100">
        <template slot-scope="scope">
          {{ formatPrice(scope.row.price) }}
        </template>
      </el-table-column>

      <el-table-column label="Concept" prop="concept" width="120">
        <template slot-scope="scope">
          {{ scope.row.concept }}
        </template>
      </el-table-column>

      <el-table-column label="Facture" prop="invoice" width="200">
        <template slot-scope="scope">
          <a
            @click="
              downloadSecuredFile(
                `uploads/invoice/${scope.row.invoice.pdf}.pdf`
              )
            "
            v-if="scope.row.invoice && scope.row.invoice.pdf"
            v-on:click.stop
          >
            {{ scope.row.invoice.filename }}
          </a>
        </template>
      </el-table-column>

      <el-table-column v-if="!client" label="Mode" prop="orderGroup">
        <template slot-scope="scope">
          <div class="d-flex justify-content-center">
            <div class="text-primary" @click="openParticipantsTabs(scope.row)">
              <i class="icon-user"></i>
              <span
                v-if="
                  scope.row.orderGroup &&
                  scope.row.orderGroup.orders &&
                  scope.row.orderGroup.orders.length > 1
                "
              >
                {{ scope.row.orderGroup.orders.length }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-if="!client"
        label="Fréquence"
        prop="sessions"
        width="100"
      >
        <template slot-scope="scope">
          <template v-if="frequency(scope.row.sessions)">
            {{ frequency(scope.row.sessions).toFixed(2) }}
          </template>
        </template>
      </el-table-column>

      <el-table-column
        v-if="!client"
        label="Intervenants"
        prop="sessions"
        width="120"
      >
        <template slot-scope="scope">
          <span v-for="(name, index) in orderCoachNames(scope.row)" :key="index"
            >{{ name }}<br
          /></span>
        </template>
      </el-table-column>

      <el-table-column label="Commercial" prop="commercials" width="140">
        <template slot-scope="scope">
          <div
            v-for="(rel, index) of scope.row.commercials"
            :key="`com-${index}`"
          >
            <CommercialRel :relation="rel" />
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Coach manager" prop="coachManagers" width="140">
        <template slot-scope="scope">
          <div
            v-for="(rel, index) of scope.row.coachManagers"
            :key="`coach-manager-${index}`"
          >
            <CoachManagerRel :relation="rel" />
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Notebook" prop="notebook" width="140">
        <template slot-scope="scope">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="showNotebook(scope.row)"
            v-tooltip="concatedNotes(scope.row)"
          >
            <i class="fa fa-commenting"></i>
            ({{ scope.row.notebook ? scope.row.notebook.length : 0 }}) Consulter
          </button>
        </template>
      </el-table-column>

      <el-table-column label="Statut" prop="status" width="210">
        <template slot-scope="scope">
          <span class="badge badge-default" v-if="scope.row.free">Offerte</span>
          <span
            class="badge badge-success"
            v-else-if="scope.row.status === 'payed'"
            >Payée</span
          >
          <span
            class="badge badge-info"
            v-else-if="scope.row.status === 'refunded'"
            >Avoir</span
          >

          <span
            class="badge badge-success"
            v-else-if="scope.row.status === 'unpaid'"
            >Impayé (traité)</span
          >

          <span
            class="badge badge-danger"
            v-else-if="scope.row.status === 'pending'"
            >En attente du paiement</span
          >
          <span
            class="badge badge-warning"
            v-else-if="scope.row.status === 'partial'"
            >Paiement partiel</span
          ><br />
          <button
            v-if="scope.row.status === 'partial' && scope.row.payments"
            type="button"
            class="btn btn-outline-warning btn-sm"
          >
            <strong>Reste à régler : {{ amountDiff(scope.row) }}</strong>
          </button>
        </template>
      </el-table-column>

      <el-table-column label="Label" prop="label" width="220">
        <template slot-scope="scope">
          <NoteModal
            :notes="scope.row.label"
            :key="scope.row.id"
            @showComment="$emit('showNotes', scope.row)"
            permission="USERS_READ"
          />
        </template>
      </el-table-column>

      <el-table-column>
        <template slot-scope="scope">
          <PaymentsHasTroubles :order="scope.row" />
        </template>
      </el-table-column>
    </el-table>

    <ModalNotebook
      :show="modalNotebook.show"
      :notebook="modalNotebook.notebook"
      :client="modalNotebook.client"
      :order="modalNotebook.order"
      @close="closeNotebook"
      @added="notebookNoteAdded"
      @updated="notebookNoteUpdated"
      @deleted="notebookNoteDeleted"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import FrequencyMixin from "../mixin/FrequencyMixin";
import DownloadFile from "../mixin/DownloadFile";
import CommercialRel from "../components/CommercialRel";
import CoachManagerRel from "../components/CoachManagerRel";
import PaymentsHasTroubles from "../components/PaymentsHasTrouble.vue";
import NoteModal from "./form/NoteModal";
import ModalNotebook from "./ModalNotebook";

export default {
  components: {
    CommercialRel,
    CoachManagerRel,
    PaymentsHasTroubles,
    NoteModal,
    ModalNotebook,
  },
  mixins: [FrequencyMixin, DownloadFile],
  props: {
    orders: {
      type: Array,
      default: null,
    },
    client: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      modalNotebook: {
        show: false,
        notebook: [],
        order: undefined,
        client: {},
      },
    };
  },

  computed: {
    ...mapState({
      concepts: (state) => state.concepts,
    }),

    showStartRowIcons() {
      const isGiftCard = this.orders.some((order) => order.giftCard);
      const isSponsorship = this.orders.some(
        (order) => order.sponsorship || order.usedSponsorship
      );
      const isFailedPayment = this.orders.some((order) =>
        this.orderHasFailedPayment(order)
      );
      return isGiftCard || isSponsorship || isFailedPayment;
    },
  },

  methods: {
    orderCoachNames(order) {
      let sessions = order.sessions || [];
      let names = sessions
        .filter((s) => s.scheduledBy)
        .map(
          (s) =>
            s.scheduledBy.coach.firstName + " " + s.scheduledBy.coach.lastName
        );
      names = [...new Set(names)];
      return names;
    },

    amountDiff(order) {
      let payed = 0;

      for (let payment of order.payments) {
        if (payment.captured) {
          payed += payment.price;
        }
      }

      return this.formatPrice(order.price - payed);
    },

    orderState(order) {
      if (order.sessions) {
        return (
          order.sessions.filter((s) => s.completed).length + "/" + order.session
        );
      }
    },

    orderHasFailedPayment(order) {
      if (!order.payments) {
        return false;
      }

      return !!order.payments.find((p) => p.failed && !p.captured);
    },

    goToOrder(e, order) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: order.id,
        },
      });

      window.open(route.href, "_blank");
    },

    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },

    openParticipantsTabs(order) {
      console.log(order);
      let ids = this.client ? [order.refClient.id] : [];

      if (order.orderGroup?.orders?.length > 0) {
        ids = [];
        let participantsOrders = order.orderGroup.orders;

        if (!this.client) {
          participantsOrders = participantsOrders.filter(
            (participantOrder) => participantOrder.id !== order.id
          );
        }

        for (const participantOrder of participantsOrders) {
          ids.push(participantOrder.refClient.id);
        }
      }

      ids.forEach((id) => {
        this.goToClient(id);
      });
    },

    showNotebook(order) {
      this.modalNotebook.order = order;
      this.modalNotebook.client = order.refClient || {};
      this.modalNotebook.notebook = order.notebook || [];
      this.modalNotebook.show = true;
    },

    closeNotebook() {
      this.modalNotebook.show = false;
      this.modalNotebook.order = null;
      this.modalNotebook.client = {};
      this.modalNotebook.notebook = [];
    },

    notebookNoteAdded(note) {
      this.$emit("notebookNoteAdded", {
        orderId: this.modalNotebook.order.id,
        note,
      });
    },

    notebookNoteUpdated(note) {
      this.$emit("notebookNoteUpdated", {
        orderId: this.modalNotebook.order.id,
        note,
      });
    },

    notebookNoteDeleted(noteId) {
      this.$emit("notebookNoteDeleted", {
        orderId: this.modalNotebook.order.id,
        noteId,
      });
    },

    concatedNotes(order) {
      return (
        order.notebook
          .filter((n) => n.orderId === order.id)
          .map((note) => `${note.title}: ${note.note}`)
          .join(" | ") || "Aucune note pour cette commande"
      );
    },
  },
};
</script>

<style lang="scss">
/*[TODO] May we improved */
.orders-custom-wrapper {
  max-width: calc(100vw - 450px);
}

@media (max-width: 991px) {
  .orders-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}
</style>
