<template>
  <modal
    title="Fiche(s) client"
    :value="show"
    effect="fade/zoom"
    large
    @ok="closeModal"
    @cancel="closeModal"
  >
    <el-table v-if="company" :data="company.clients" max-height="550">
      <el-table-column label="Nom">
        <template slot-scope="scope">
          <a href="#" @click="goToClient(scope.row.id)">
            <span v-if="scope.row.firstName && scope.row.lastName"
              >{{ scope.row.firstName }} {{ scope.row.lastName }}</span
            >

            <span v-else>{{ scope.row.email }}</span>
          </a>
        </template>
      </el-table-column>

      <el-table-column label="Entreprise">
        {{ company.name }}
      </el-table-column>

      <el-table-column label="Statut">
        <template slot-scope="scope">
          <StatusRel v-if="scope.row.status" :status="scope.row.status" />

          <p v-if="scope.row.status === 'lost' && scope.row.lostReason">
            {{ lostReasonLabel(scope.row.lostReason) }}
          </p>
        </template>
      </el-table-column>

      <el-table-column label="Facturation" sortable prop="isB2BBilling">
        <template slot-scope="scope">
          {{ scope.row.isB2BBilling ? "Oui" : "Non" }}
        </template>
      </el-table-column>
    </el-table>
    <div slot="modal-footer" class="modal-footer d-flex justify-end">
      <button type="button" class="btn btn-secondary" @click="closeModal">
        Fermer
      </button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";

import Modal from "vue-strap/src/Modal";
import StatusRel from "../StatusRel";

export default {
  components: {
    Modal,
    StatusRel,
  },

  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
      companyLost: "getCompanyLost",
    }),

    globalLostReasons() {
      return this.companyLost.concat(this.lostReasons);
    },
  },

  watch: {
    company() {
      this.show = !!this.company;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },

    lostReasonLabel(value) {
      const lostReason = this.globalLostReasons.find(
        (reason) => reason.value === value
      );

      return lostReason?.label || null;
    },
  },
};
</script>
