<template>
  <modal
    :title="'Créer'"
    v-model="modalOpened"
    cancelText="Fermer"
    :okText="'Créer'"
    @cancel="() => closeModal()"
    @ok="handleAddInvitationCode()"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">Créer des codes</h4>
    </div>
    <div v-if="formError" class="alert alert-danger">{{ formError }}</div>

    <div class="row">
      <div class="col-sm-6">
        <label>Date de début</label>
        <datepicker
          v-model="form.startDate"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="col-sm-6">
        <label>Date de fin</label>
        <datepicker
          v-model="form.endDate"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-4">
        <div class="form-group">
          <label>Nombre de codes *</label>
          <div class="input-group">
            <input
              type="number"
              v-model="form.count"
              min="0"
              max="100"
              step="1"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="form-group">
          <label>Categorie *</label>
          <div class="input-group">
            <v-select
              :options="categories"
              v-model="form.category"
              label="name"
              value="id"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label>Durée en jours *</label>
          <div class="input-group">
            <input
              type="number"
              v-model="form.duration"
              min="0"
              max="1234"
              step="1"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="form-group">
          <label>Type *</label>
          <div class="input-group">
            <v-select
              :options="types"
              v-model="form.type"
              :reduce="({ value }) => value"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label>Donne accès à l'entreprise</label>
          <select v-model="form.companyId" class="form-control">
            <option v-for="{ id, name } in companies" :key="id" :value="id">
              {{ name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";

import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";
import Loader from "../components/Loader";

export default {
  components: {
    Modal,
    Datepicker,
    Loader,
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    form: {
      startDate: moment().toDate(),
      endDate: moment().add(1, "y").toDate(),
      count: null,
      type: null,
      duration: null,
      category: null,
      companyId: null,
    },
    companies: [],
    modalOpened: false,
    formError: false,
    loading: false,
  }),

  async mounted() {
    this.modalOpened = this.modalShow;

    await this.getCompanies();
  },

  methods: {
    async handleAddInvitationCode() {
      if (this.loading) {
        return false;
      }

      this.formError = false;
      this.loading = true;

      try {
        this.validateForm();

        if (!this.formError) {
          await this.$api.post("/invitation-codes", {
            ...this.form,
            categoryId: this.form.category.id,
          });

          this.closeModal();
        }
      } catch (e) {
        this.formError = `Une erreur s'est produite, veuillez réessayer`;
      }

      this.loading = false;
    },

    async getCompanies() {
      try {
        const { data } = await this.$api.get("/companies/simples");

        this.companies = data;
      } catch (e) {
        this.companies = [];
      }
    },

    closeModal() {
      this.modalOpened = false;
      this.loading = false;
      this.formError = false;

      this.$emit("close");
    },

    validateForm() {
      if (
        !this.form.startDate ||
        !this.form.endDate ||
        !this.form.count ||
        !this.form.duration ||
        !this.form.category ||
        !this.form.type
      ) {
        this.formError = "Tous les champs sont obligatoires";
        return;
      }
    },
  },

  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
        this.modalOpened = newVal;
      }
    },
  },
};
</script>
