var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('router-view'),_c('loader',{attrs:{"isVisible":_vm.isLoading}}),_c('div',{staticClass:"animated fadeIn"},[_c('ClientsProspectsFilters',{attrs:{"type":"client","form":_vm.filter,"defaultCommercials":_vm.defaultCommercials,"commercials":_vm.commercials,"concepts":_vm.concepts},on:{"applyFilters":_vm.applyFilters,"addNew":function($event){_vm.showNewClientModal = true}}}),(['clients'].indexOf(_vm.$route.name) > -1)?_c('div',{staticClass:"card"},[(_vm.clients.all)?_c('div',{staticClass:"card-block"},[_c('tabs',{on:{"input":_vm.handleNewActiveTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('tab',{attrs:{"header":_vm.allHeader}},[_c('clients',{attrs:{"clients":_vm.clients.all,"rows":_vm.limit,"totalClients":_vm.totals.all,"page":_vm.page,"sorting":{
                orderBy: _vm.filter.orderBy,
                orderByReverse: _vm.filter.orderByReverse,
              }},on:{"archiveClient":_vm.archiveClientModalShow,"showComment":_vm.displayComment,"changePage":_vm.changePage,"sortClients":_vm.sortClients}})],1),_c('tab',{attrs:{"header":_vm.activeHeader}},[_c('clients',{attrs:{"clients":_vm.clients.active,"rows":_vm.limit,"totalClients":_vm.totals.active,"page":_vm.page,"sorting":{
                orderBy: _vm.filter.orderBy,
                orderByReverse: _vm.filter.orderByReverse,
              }},on:{"archiveClient":_vm.archiveClientModalShow,"showComment":_vm.displayComment,"changePage":_vm.changePage,"sortClients":_vm.sortClients}})],1),_c('tab',{attrs:{"header":_vm.inactiveHeader}},[_c('clients',{attrs:{"clients":_vm.clients.inactive,"rows":_vm.limit,"totalClients":_vm.totals.inactive,"page":_vm.page,"sorting":{
                orderBy: _vm.filter.orderBy,
                orderByReverse: _vm.filter.orderByReverse,
              }},on:{"archiveClient":_vm.archiveClientModalShow,"showComment":_vm.displayComment,"changePage":_vm.changePage,"sortClients":_vm.sortClients}})],1),_c('tab',{attrs:{"header":_vm.lastInactiveHeader}},[_c('clients',{attrs:{"clients":_vm.clients.lastInactive,"rows":_vm.limit,"totalClients":_vm.totals.lastInactive,"page":_vm.page,"sorting":{
                orderBy: _vm.filter.orderBy,
                orderByReverse: _vm.filter.orderByReverse,
              }},on:{"archiveClient":_vm.archiveClientModalShow,"showComment":_vm.displayComment,"changePage":_vm.changePage,"sortClients":_vm.sortClients}})],1),_c('tab',{attrs:{"header":_vm.inactiveFinishedPackHeader}},[_c('clients',{attrs:{"clients":_vm.clients.inactiveFinishedPack,"rows":_vm.limit,"totalClients":_vm.totals.inactiveFinishedPack,"page":_vm.page,"sorting":{
                orderBy: _vm.filter.orderBy,
                orderByReverse: _vm.filter.orderByReverse,
              }},on:{"archiveClient":_vm.archiveClientModalShow,"showComment":_vm.displayComment,"changePage":_vm.changePage,"sortClients":_vm.sortClients}})],1),_c('tab',{attrs:{"header":_vm.suspendedHeader}},[_c('clients',{attrs:{"clients":_vm.clients.suspended,"rows":_vm.limit,"totalClients":_vm.totals.suspended,"page":_vm.page,"sorting":{
                orderBy: _vm.filter.orderBy,
                orderByReverse: _vm.filter.orderByReverse,
              }},on:{"archiveClient":_vm.archiveClientModalShow,"showComment":_vm.displayComment,"changePage":_vm.changePage,"sortClients":_vm.sortClients}})],1),_c('tab',{attrs:{"header":_vm.lostHeader}},[_c('clients',{attrs:{"clients":_vm.clients.lost,"rows":_vm.limit,"totalClients":_vm.totals.lost,"page":_vm.page,"sorting":{
                orderBy: _vm.filter.orderBy,
                orderByReverse: _vm.filter.orderByReverse,
              }},on:{"archiveClient":_vm.archiveClientModalShow,"showComment":_vm.displayComment,"changePage":_vm.changePage,"sortClients":_vm.sortClients}})],1)],1)],1):_vm._e()]):_vm._e()],1),_c('modal',{attrs:{"title":"Archiver un client","cancelText":"Fermer","okText":"Archiver","effect":"fade/zoom"},on:{"ok":function($event){return _vm.archiveClient()}},model:{value:(_vm.archiveClientModal.display),callback:function ($$v) {_vm.$set(_vm.archiveClientModal, "display", $$v)},expression:"archiveClientModal.display"}},[(_vm.archiveClientModal.client)?_c('div',[_c('p',[_vm._v(" Voulez-vous vraiment archiver le client "),_c('strong',[_vm._v(_vm._s(_vm.archiveClientModal.client.firstName)+" "+_vm._s(_vm.archiveClientModal.client.lastName)+" ")]),_vm._v(" ? ")])]):_vm._e()]),_c('NewClientModal',{attrs:{"show":_vm.showNewClientModal,"default":_vm.form,"commercials":_vm.commercials,"concepts":_vm.concepts},on:{"close":function($event){_vm.showNewClientModal = false}}}),_c('ClientNoteModal',{attrs:{"show":_vm.modalUpdateNote.show,"client":_vm.modalUpdateNote.client},on:{"close":function($event){_vm.modalUpdateNote.show = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }