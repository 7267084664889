<template>
  <div class="card">
    <Loader :is-visible="loading" />

    <div class="card-header">
      <i class="fa fa-location-arrow"></i> Adresses
      <button
        v-if="userHasWritePermission"
        type="button"
        class="btn btn-primary btn-sm float-right"
        @click="add"
      >
        <i class="fa fa-plus mr-05"></i>
        Ajouter
      </button>
    </div>

    <div class="card-block">
      <tabs v-model="activeTab">
        <tab :header="activeHeader">
          <CompanyLocalisationsTable
            :data="active"
            @edit="edit"
            @delete="deleteCompanyLocalisations"
            @activate="toggleActive"
          />
        </tab>
        <tab :header="inactiveHeader">
          <CompanyLocalisationsTable
            :data="inactive"
            @edit="edit"
            @delete="deleteCompanyLocalisations"
            @activate="toggleActive"
          />
        </tab>
      </tabs>
    </div>

    <CompanyLocalisationModal
      :show="showModal"
      :company-localisation="selectedCompanyLocalisation"
      @close="closeModal"
      @edit="editCompanyLocalisation"
      @add="addNewCompanyLocalisation"
    />
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import CompanyLocalisationsTable from "./CompanyLocalisationsTable";
import CompanyLocalisationModal from "./CompanyLocalisationModal";
import Loader from "../Loader";

export default {
  components: {
    Tabs,
    Tab,
    CompanyLocalisationsTable,
    CompanyLocalisationModal,
    Loader,
  },

  props: {
    companyLocalisations: {
      type: Array,
      default: () => [],
    },
    companyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      data: [],
      activeTab: 0,
      showModal: false,
      selectedCompanyLocalisation: null,
    };
  },

  computed: {
    active() {
      return this.data.filter((compLoc) => compLoc.active);
    },

    inactive() {
      return this.data.filter((compLoc) => !compLoc.active);
    },

    activeHeader() {
      return `Actives (${this.active.length})`;
    },

    inactiveHeader() {
      return `Inactives (${this.inactive.length})`;
    },

    userHasWritePermission() {
      return this.$route.name === "public-procurement-details"
        ? this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          )
        : this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE");
    },
  },

  watch: {
    companyLocalisations(companyLocalisations) {
      this.data = companyLocalisations || [];
    },
  },

  mounted() {
    this.data = this.companyLocalisations;
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.selectedCompanyLocalisation = null;
    },

    edit(companyLocalisation) {
      this.selectedCompanyLocalisation = companyLocalisation;
      this.showModal = true;
    },

    add() {
      this.selectedCompanyLocalisation = null;
      this.showModal = true;
    },

    async editCompanyLocalisation(form) {
      try {
        this.loading = true;

        const { localisationId, address, zipcode, city, country, name } = form;

        const { data: companyLocalisation } = await this.$api.put(
          `/companies/${this.companyId}/localisations/${localisationId}`,
          {
            name,
            address,
            zipcode,
            city,
            country,
          }
        );

        this.data = this.data.map((compLoc) => {
          if (compLoc.id === companyLocalisation.id) {
            return companyLocalisation;
          }

          return compLoc;
        });
      } catch (e) {
        this.$errorToast("Impossible de mettre à jour la localisation");
      } finally {
        this.loading = false;

        this.closeModal();
      }
    },

    async addNewCompanyLocalisation(form) {
      try {
        this.loading = true;

        const { address, zipcode, city, country, name } = form;

        const { data: companyLocalisation } = await this.$api.post(
          `/companies/${this.companyId}/localisations`,
          {
            name,
            address,
            zipcode,
            city,
            country,
          }
        );

        this.data.unshift(companyLocalisation);
      } catch (e) {
        this.$errorToast("Impossible d'ajouter la localisation");
      } finally {
        this.loading = false;

        this.closeModal();
      }
    },

    async deleteCompanyLocalisations(companyLocalisation) {
      try {
        this.loading = true;

        await this.$api.delete(
          `/companies/${this.companyId}/localisations/${companyLocalisation.localisationId}`
        );

        this.data = this.data.filter(
          (compLoc) => compLoc.id !== companyLocalisation.id
        );
      } catch (e) {
        this.$errorToast("Impossible de supprimer la localisation");
      } finally {
        this.loading = false;
      }
    },

    async toggleActive(companyLocalisation) {
      try {
        const { localisationId, active } = companyLocalisation;

        await this.$api.patch(
          `/companies/${this.companyId}/localisations/${localisationId}/active`,
          {
            active,
          }
        );
      } catch (e) {
        const error = companyLocalisation.active
          ? "Impossible d'activer la localisation"
          : "Impossible de désactiver la localisation";

        this.$errorToast(error);

        companyLocalisation.active = !companyLocalisation.active;
      }
    },
  },
};
</script>
