<template>
  <div class="wrapper">
    <router-view></router-view>
    <Loader :isVisible="loading" />
    <div
      class="animated fadeIn"
      v-if="['public-procurements-list'].indexOf($route.name) > -1"
    >
      <CompaniesListFilters
        v-if="commercials"
        :commercials="commercials"
        :defaultFilters="filters"
        @apply-filters="applyFilters"
      />

      <div class="card">
        <div class="card-header">
          <span><i class="icon-people mr-05"></i> Marchés publics</span>
          <button
            v-if="hasPermission($store.state.user, 'PUBLIC_PROCUREMENTS_WRITE')"
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="showCreateModal = true"
          >
            <i class="icon-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block display-tab">
          <el-table
            :data="publicProcurements"
            :default-sort="{ prop: 'name', order: 'ascending' }"
            @row-click="goToPublicProcurement"
          >
            <el-table-column type="index" label="#" fixed sortable>
              <template slot-scope="scope">
                {{ offset + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="Nom" prop="name" sortable min-width="200">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="Statut" width="180">
              <template slot-scope="scope">
                <el-select
                  :value="scope.row.statut"
                  size="mini"
                  @change="openStatusConfirm($event, scope.row.id)"
                  :disabled="
                    !hasPermission(
                      $store.state.user,
                      'PUBLIC_PROCUREMENTS_WRITE'
                    )
                  "
                  class="w-100"
                  style="border-radius: 5px"
                  :class="`status-color__${scope.row.statut}`"
                >
                  <el-option
                    v-for="status in companyStatuts"
                    :key="status.label"
                    :value="status.value"
                    :label="status.label"
                    :class="`status-color__${status.value}`"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Clients" align="center">
              <template slot-scope="scope">
                <a href="#" @click.stop="showCompanyClients = scope.row">
                  <i class="fa fa-user"></i> {{ scope.row.clients.length }}</a
                >
              </template>
            </el-table-column>
            <el-table-column label="Note" width="250">
              <template slot-scope="scope">
                <NoteModal
                  style="margin-top: 1px"
                  :notes="scope.row.note"
                  :key="scope.row.note"
                  v-if="
                    hasPermission(
                      $store.state.user,
                      'PUBLIC_PROCUREMENTS_WRITE'
                    )
                  "
                  @showComment="companyEditNote = { ...scope.row }"
                />
                <span v-else>
                  {{ scope.row.note }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="Commercial" align="center" min-width="180">
              <template slot-scope="scope">
                <template v-for="(rel, index) of scope.row.commercials">
                  <CommercialRel :key="`com-${index}`" :relation="rel" />
                </template>
              </template>
            </el-table-column>

            <el-table-column
              align="right"
              v-if="isAdmin($store.state.user)"
              width="100"
            >
              <template slot-scope="scope">
                <button
                  class="btn btn-danger btn-sm"
                  @click.stop="deleteCompany(scope.row.id)"
                >
                  <i class="fa fa-trash-o"></i>
                </button>
              </template>
            </el-table-column>
          </el-table>

          <paginate
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="handleCurrentPageChange"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
            style="padding: 1.25rem 0"
          ></paginate>
        </div>
      </div>
    </div>

    <CompanyCreateModal
      :show="showCreateModal"
      :commercials="commercials"
      @close="showCreateModal = false"
    />
    <CompanyStatusModal
      :statusConfirmModal="statusConfirmModal"
      @update="editCompanyStatut"
    />
    <CompanyClientsModal
      :company="showCompanyClients"
      @close="showCompanyClients = null"
    />
    <modal
      title="Note"
      :value="!!companyEditNote"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateNote"
      @cancel="companyEditNote = null"
      effect="fade/zoom"
    >
      <textarea
        v-if="companyEditNote"
        v-model="companyEditNote.note"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";
import CompaniesListFilters from "../components/CompaniesListFilters";
import NoteModal from "../components/form/NoteModal";
import CommercialRel from "../components/CommercialRel";
import Paginate from "vuejs-paginate";
import CompanyStatusModal from "../components/company/CompanyStatusModal";
import CompanyCreateModal from "../components/company/CompanyCreateModal";
import CompanyClientsModal from "../components/company/CompanyClientsModal";

export default {
  components: {
    Modal,
    Loader,
    CompaniesListFilters,
    NoteModal,
    CommercialRel,
    Paginate,
    CompanyStatusModal,
    CompanyCreateModal,
    CompanyClientsModal,
  },

  data() {
    return {
      loading: false,
      currentPage: 1,
      limit: 15,
      totals: 0,
      publicProcurements: [],
      commercials: undefined,
      filters: {
        statuts: [],
        companyName: "",
        commercials: [],
      },
      showCreateModal: false,
      showCompanyClients: null,
      statusConfirmModal: {
        show: false,
        statut: null,
        companyId: null,
      },
      companyEditNote: null,
    };
  },

  computed: {
    ...mapGetters({
      companyStatuts: "getCompanyStatuts",
    }),

    pageCount() {
      if (!this.totals) {
        return 0;
      }

      return Math.ceil(this.totals / this.limit);
    },

    offset() {
      return (this.currentPage - 1) * this.limit;
    },
  },

  async created() {
    await this.fetchCommercials();
    await this.fetchPublicProcurements();
  },

  methods: {
    async fetchCommercials() {
      try {
        const { data: b2bCommercials } = await this.$api.get(
          "/users/commercials/search",
          {
            params: { b2b: true },
          }
        );

        const { data: publicProcurementsCommercials } = await this.$api.get(
          "/users/commercials/search",
          {
            params: { commercials: ["marche@justcoaching.fr"] },
          }
        );

        this.commercials = [
          ...b2bCommercials,
          ...publicProcurementsCommercials,
        ].map((c) => ({
          value: c.email,
          label: c.commercial.pseudo,
        }));

        this.filters.commercials = publicProcurementsCommercials.map(
          (c) => c.email
        );
      } catch (e) {
        this.commercials = [];
      }
    },

    async fetchPublicProcurements() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/companies", {
          params: {
            limit: this.limit,
            offset: this.offset,
            isPublicProcurement: true,
            ...this.filters,
          },
        });

        this.publicProcurements = data.data;
        this.totals = data.totals;
      } catch (e) {
        this.publicProcurements = [];
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };

      this.handleCurrentPageChange(1);
    },

    handleCurrentPageChange(page) {
      this.currentPage = page;

      this.fetchPublicProcurements();
    },

    goToPublicProcurement({ id }) {
      const route = this.$router.resolve({
        name: "public-procurement-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    openStatusConfirm(value, companyId) {
      if (value) {
        this.statusConfirmModal.show = true;
        this.statusConfirmModal.statut = value;
        this.statusConfirmModal.companyId = companyId;
      }
    },

    async editCompanyStatut({ statut, lostReason }) {
      if (lostReason && lostReason.value) {
        lostReason = lostReason.value;
      }

      await this.$api.put(`/companies/${this.statusConfirmModal.companyId}`, {
        statut,
        lostReason,
      });

      this.publicProcurements = this.publicProcurements.map(
        (publicProcurement) => {
          if (publicProcurement.id === this.statusConfirmModal.companyId) {
            publicProcurement.statut = statut;
          }

          return publicProcurement;
        }
      );
    },

    async updateNote() {
      if (!this.companyEditNote) {
        return;
      }

      try {
        const { id, note } = this.companyEditNote;

        await this.$api.put(`/companies/${id}`, {
          note: note.trim(),
        });

        this.publicProcurements = this.publicProcurements.map(
          (publicProcurement) => {
            if (publicProcurement.id === id) {
              publicProcurement.note = note.trim();
            }

            return publicProcurement;
          }
        );

        this.companyEditNote = null;
      } catch (e) {
        this.$errorToast("Impossible de modifier la note");
      }
    },

    async deleteCompany(id) {
      this.$confirm({
        message:
          "Voulez-vous vraiment supprimer ce marché public ? Cette action est irréversible.",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/companies/${id}`);

            this.fetchPublicProcurements();
          } catch (e) {
            this.$errorToast("Impossible de supprimer le marché public");
          }
        },
      });
    },
  },
};
</script>
