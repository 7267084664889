<template>
  <modal
    title="Modifier le statut"
    :value="isVisible"
    :backdrop="false"
    cancelText="Annuler"
    okText="Enregistrer"
    @ok="submit()"
    @cancel="closeModalUpdateClientStatus"
    effect="fade/zoom"
  >
    Êtes vous sûr de vouloir modifier le statut en
    <span class="font-weight-bold">'{{ displayCompanyStatut }}'</span>

    <div class="row" v-if="statut === 'lost'">
      <div class="col-12 mt-1">
        Raison :
        <v-select :options="globalLostReasons" v-model="form.lostReason">
        </v-select>
      </div>

      <div class="card card-inverse card-danger text-center mt-2">
        <div class="card-block">
          <blockquote class="card-blockquote">
            Attention ! En passant la société en perdu vous aller passer tous
            les clients de cette société en perdu. Cette action est réversible
            uniquement fiche par fiche.
          </blockquote>
        </div>
      </div>
    </div>
    <div v-if="errors.length" class="col-sm-12">
      <div class="alert alert-danger mb-0">
        <ul style="margin-bottom: 0">
          <li
            v-for="(error, index) in errors"
            :key="`localisation-form-error-${index}`"
          >
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import { mapGetters } from "vuex";

export default {
  components: {
    Modal,
  },

  props: {
    statusConfirmModal: {
      type: Object,
      default: () => ({
        show: false,
        statut: null,
      }),
    },
  },

  data() {
    return {
      isVisible: false,
      statut: null,
      form: {
        lostReason: null,
      },

      errors: [],
    };
  },

  computed: {
    ...mapGetters({
      companyStatuts: "getCompanyStatuts",
      lostReasons: "getLostReasons",
      companyLost: "getCompanyLost",
    }),

    globalLostReasons() {
      return this.companyLost.concat(this.lostReasons);
    },

    canEdit() {
      return this.hasPermission(this.$store.state.user, "USERS_WRITE");
    },

    displayCompanyStatut() {
      return (
        this.companyStatuts.find((statut) => statut.value === this.statut)
          ?.label || ""
      );
    },
  },

  watch: {
    statusConfirmModal: {
      deep: true,
      handler(value) {
        this.isVisible = value.show;
        this.statut = value.statut;
      },
    },
  },

  methods: {
    submit() {
      this.checkFields();

      if (this.errors.length) return;

      this.$emit("update", {
        statut: this.statut,
        lostReason: this.form.lostReason?.value || undefined,
      });

      this.isVisible = false;
      this.statut = null;
    },

    checkFields() {
      this.errors = [];

      if (
        this.statut === "lost" &&
        (!this.form.lostReason || !this.form.lostReason.value)
      ) {
        this.errors.push("Le champs raison est obligatoire");
      }
    },

    closeModalUpdateClientStatus() {
      this.isVisible = false;
      this.statut = null;

      this.$emit("close");
    },
  },
};
</script>
