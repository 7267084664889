<template>
  <div class="card">
    <Loader :isVisible="loading" />
    <div class="card-header d-flex justify-content-between">
      <div><i class="fa fa-bullhorn mr-05"></i> Marché public</div>
      <el-switch
        :value="company.isPublicProcurement"
        size="mini"
        :disabled="!userHasWritePermission"
        @change="toggleIsPublicProcurement"
      />
    </div>
  </div>
</template>

<script>
import Loader from "../Loader";

export default {
  components: {
    Loader,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    userHasWritePermission() {
      return (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE") &&
        this.hasPermission(this.$store.state.user, "PUBLIC_PROCUREMENTS_WRITE")
      );
    },
  },

  methods: {
    toggleIsPublicProcurement(isPublicProcurement) {
      this.$confirm({
        message: isPublicProcurement
          ? "Voulez-vous vraiment passer cette entreprise en marché public ?"
          : "Voulez-vous vraiment passer ce marché public en entreprise ?",
        onConfirm: async () => {
          try {
            this.loading = true;

            await this.$api.put(`/companies/${this.company.id}`, {
              isPublicProcurement,
            });

            const route = this.$router.resolve({
              name: isPublicProcurement
                ? "public-procurement-details"
                : "company-details",
              params: {
                id: this.company.id,
              },
            });

            window.open(route.href, "_self");
          } catch (e) {
            this.$errorToast(
              isPublicProcurement
                ? "Impossible de passer cette entreprise en marché public"
                : "Impossible de passer ce marché public en entreprise"
            );
          } finally {
            this.loading = false;
          }
        },
      });
    },
  },
};
</script>
