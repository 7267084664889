<template>
  <div v-if="features.length > 0" class="card">
    <div class="card-header">
      <span><i class="fa fa-toggle-on mr-05"></i> Fonctionnalités</span>
    </div>
    <div class="card-block">
      <div
        v-for="(feature, index) in features"
        :key="feature.name"
        :class="index > 0 ? 'mt-1' : ''"
      >
        <el-switch
          :value="companyFeatures.includes(feature.name)"
          size="mini"
          :disabled="!userHasWritePermission"
          @change="toggleCompanyFeature(feature.name)"
        />
        <label class="ml-1">{{ feature.nicename }}</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: feature.description, placement: 'right' }"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      companyFeatures: [],
      features: [],
    };
  },

  computed: {
    userHasWritePermission() {
      return this.$route.name === "public-procurement-details"
        ? this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          )
        : this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE");
    },
  },

  created() {
    this.fetchFeatures();

    this.companyFeatures = this.company.features.map(
      (feature) => feature.featureName
    );
  },

  methods: {
    async fetchFeatures() {
      try {
        const { data } = await this.$api.get("/features/company");

        this.features = data;
      } catch (e) {
        this.features = [];
      }
    },

    async toggleCompanyFeature(featureName) {
      try {
        if (this.companyFeatures.includes(featureName)) {
          await this.$api.delete(
            `/companies/${this.company.id}/features/${featureName}`
          );

          this.companyFeatures = this.companyFeatures.filter(
            (feature) => feature !== featureName
          );
        } else {
          await this.$api.post(
            `/companies/${this.company.id}/features/${featureName}`
          );

          this.companyFeatures.push(featureName);
        }
      } catch (e) {
        this.$errorToast("Impossible de modifier la fonctionnalité");
      }
    },
  },
};
</script>
