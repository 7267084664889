<template>
  <el-table
    :data="data"
    :row-class-name="tableRowClassName"
    max-height="250"
    @row-click="editRow"
  >
    <el-table-column
      v-if="userHasWritePermission"
      prop="active"
      align="center"
      width="60"
    >
      <template slot-scope="scope">
        <el-switch
          v-model="scope.row.active"
          size="mini"
          @click.stop=""
          @change="toggleActive(scope.row)"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="localisation.address"
      label="Adresse"
      min-width="200"
    >
      <template slot-scope="scope">
        <span class="font-weight-bold">{{ scope.row.name }}</span>
        <p class="mb-0">
          <i
            v-if="scope.row.isDefault"
            class="fa fa-star"
            style="color: #fae54d"
          ></i>
          {{ scope.row.localisation.address }}
        </p>
      </template>
    </el-table-column>
    <el-table-column
      prop="localisation.zipcode"
      label="CP"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="localisation.city"
      label="Ville"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="localisation.country"
      label="Pays"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="updatedAt"
      label="Mis à jour le"
      width="120"
      align="center"
    >
      <template slot-scope="scope">
        {{ formatDate(scope.row.updatedAt) }}
      </template>
    </el-table-column>
    <el-table-column align="center">
      <template slot-scope="scope">
        <button
          v-if="!scope.row.isDefault && userHasWritePermission"
          type="button"
          class="btn btn-sm btn-danger"
          @click.stop="deleteRow(scope.row)"
        >
          <i class="fa fa-times"></i>
        </button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    userHasWritePermission() {
      return this.$route.name === "public-procurement-details"
        ? this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          )
        : this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE");
    },
  },

  methods: {
    tableRowClassName({ row }) {
      return row.isDefault ? "default-localisation-row" : "";
    },

    editRow(row) {
      if (row.isDefault || !this.userHasWritePermission) return;

      this.$emit("edit", row);
    },

    deleteRow(row) {
      if (row.isDefault) return;

      this.$confirm({
        message: "Êtes-vous sûr de vouloir supprimer cette localisation ?",
        onConfirm: () => {
          this.$emit("delete", row);
        },
      });
    },

    toggleActive(row) {
      this.$emit("activate", row);
    },
  },
};
</script>

<style lang="postcss">
.el-table {
  .default-localisation-row {
    background-color: #f7f7f7;
    cursor: default;
  }
}
</style>
