import Vue from "vue";
import Router from "vue-router";
import store from "../store";

// Containers
import Full from "../containers/Full";
import Login from "../containers/Login";
import Checkout from "../containers/Checkout";

// Views
import CompaniesNav from "../views/CompaniesNav";
import CompaniesList from "../views/CompaniesList";
import CompanyDetails from "../views/CompanyDetails";
import PublicProcurementsList from "../views/PublicProcurementsList";
import CoachManager from "../views/CoachManager";
import CoachManagerLost from "../views/CoachManagerLost";
import CoachManagerStatus from "../views/CoachManagerStatus";
import CoachManagerConversion from "../views/CoachManagerConversion";
import CoachManagerFidelisation from "../views/CoachManagerFidelisation";
import CoachManagerScrapping from "../views/CoachManagerScrapping";
import CoachManagerInternalNote from "../views/CoachManagerInternalNote";
import CoachManagerClientsNote from "../views/CoachManagerClientsNote";
import Dashboard from "../views/Dashboard";
import Search from "../views/Search";
import Invoices from "../views/Invoices";
import Orders from "../views/Orders";
import OrdersDetails from "../views/OrdersDetails";
import Clients from "../views/Clients";
import ClientsNav from "../views/ClientsNav";
import ClientsSubstitutions from "../views/ClientsSubstitutions";
// import ClientsGroups from "../views/ClientsGroups";
// import ClientsGroupDetails from "../views/ClientsGroupDetails";
import ClientsDetails from "../views/ClientsDetails";
import Users from "../views/Users";
import UsersDetails from "../views/UsersDetails";
import UsersNav from "../views/UsersNav";
import Merchants from "../views/Merchants";
import MerchantsDetails from "../views/MerchantsDetails";
import Substitutions from "../views/Substitutions";
import Disbursements from "../views/Disbursements";
import DisbursementsPending from "../views/DisbursementsPending";
import DisbursementsProcessed from "../views/DisbursementsProcessed";
import DisbursementsPayed from "../views/DisbursementsPayed";
import DisbursementsBlocked from "../views/DisbursementsBlocked";
import DisbursementsTroubles from "../views/DisbursementsTroubles";
import Attributions from "../views/Attributions";
import AttributionsOrder from "../views/AttributionsOrder";
import AttributionsClient from "../views/AttributionsClient";
import AttributionsSession from "../views/AttributionsSession";
import Documents from "../views/Documents";
import Billing from "../views/Billing";
import Export from "../views/Export";
import ExportClients from "../views/ExportClients";
import ExportProspects from "../views/ExportProspects";
import ExportOrders from "../views/ExportOrders";
import ExportMarketingForm from "../views/ExportMarketingForm";
import ExportNova from "../views/ExportNova";
import ExportSoldeComptable from "../views/ExportSoldeComptable";
import ExportPrestataires from "../views/ExportPrestataires";
import ExportCompta from "../views/ExportCompta";
import Payments from "../views/Payments";
import Fidelisation from "../views/Fidelisation";
import Conversion from "../views/Conversion";
import Payouts from "../views/Payouts";
import Unpaid from "../views/Unpaid";
import Settings from "../views/Settings";
import SettingsTeamMembers from "../views/SettingsTeamMembers";
import SettingsTeamMembersDetails from "../views/SettingsTeamMembersDetails";
import SettingsConfigs from "../views/SettingsConfigs";
import SettingsConcepts from "../views/SettingsConcepts";
import SettingsConceptsDetails from "../views/SettingsConceptsDetails";
import SettingsSap from "../views/SettingsSap";
import SettingsFaq from "../views/SettingsFaq";
import SettingsFaqDetails from "../views/SettingsFaqDetails";
import SettingsIntervenants from "../views/SettingsIntervenants";
import SettingsInvitationCode from "../views/SettingsInvitationCode";
import SettingsSponsorship from "../views/SettingsSponsorship";
import SettingsPromoCodes from "../views/SettingsPromoCodes";
import SettingsPaymentMethods from "../views/SettingsPaymentMethods";
import SettingsPaymentMethodsDetails from "../views/SettingsPaymentMethodsDetails";
import SettingsDynamicContent from "../views/SettingsDynamicContent";
import SettingsCustomUserEvent from "../views/SettingsCustomUserEvent.vue";
import DynamicContentNav from "../views/DynamicContentNav.vue";
import Stats from "../views/Stats";
import Live from "../views/Live/Index";
import Origins from "../views/Live/Origins";
import LiveCourses from "../views/Live/Courses";
import LiveCalendar from "../views/Live/Calendar";
import LiveReplays from "../views/Live/Replays";
import LiveStatistics from "../views/Live/Statistics";
import LiveClients from "../views/Live/Clients";
import Suggestions from "../views/Live/Suggestions.vue";
import LiveGlobalInvoice from "../views/Live/GlobalInvoice";
import Import from "../views/Import";
import ImportPrestataires from "../views/ImportPrestataires";
import StatsExpense from "../views/StatsExpense";
import StatsFormExpense from "../views/StatsFormExpense";
import StatsMarketing from "../views/StatsMarketing";
import StatsMarketingKpi from "../views/StatsMarketingKpi";
import StatsROAS from "../views/StatsROAS";
import StatsAcquisitionFidelisation from "../views/StatsAcquisitionFidelisation";
import StatsPayments from "../views/StatsPayments";
import StatsLost from "../views/StatsLost";
import StatsLostProspectsClients from "../views/StatsLostProspectsClients";
import StatsLostSources from "../views/StatsLostSources";
import StatsLostMap from "../views/StatsLostMap";
import StatsCommercials from "../views/StatsCommercials";
import StatsStatsCommercialsV2 from "../views/StatsStatsCommercialsV2";
import StatsAttribution from "../views/StatsAttribution";
import StatsSponsorship from "../views/StatsSponsorship";
import StatsLive from "../views/StatsLive";
import StatsCARatios from "../views/StatsCARatios";
import StatsCARatiosCouts from "../views/StatsCARatiosCouts";
import ClientTab from "../views/ClientTab";
import ProspectTab from "../views/ProspectTab";
import UsersProfilePictures from "../views/UsersProfilePictures";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      name: "login",
      path: "/login",
      component: Login,
    },
    {
      name: "stripe",
      path: "/order/:id/:success?",
      component: Checkout,
    },
    {
      path: "/",
      name: "Home",
      component: Full,
      children: [
        {
          path: "search",
          name: "search",
          meta: {
            label: "Recherche",
          },
          component: Search,
        },
        {
          path: "dashboard",
          name: "dashboard",
          meta: {
            label: "Dashboard",
            requiredPermission: "DASHBOARD_READ",
          },
          component: Dashboard,
        },
        {
          path: "orders",
          name: "orders",
          meta: {
            label: "Commandes",
            requiredPermission: "ORDERS_READ",
          },
          component: Orders,
          children: [
            {
              path: ":id",
              component: OrdersDetails,
              name: "order-details",
              meta: {
                label: "Détails de la commande",
                requiredPermission: "ORDERS_READ",
              },
            },
          ],
        },
        {
          path: "billing",
          name: "billing",
          meta: {
            label: "Facturation",
            requiredPermission: "BILLING_READ",
          },
          component: Billing,
          children: [
            {
              path: "invoices",
              name: "invoices",
              meta: {
                label: "Factures",
                requiredPermission: "INVOICES_READ",
              },
              component: Invoices,
            },
            {
              name: "payments",
              path: "payments",
              meta: {
                label: "Paiements",
                requiredPermission: "PAYMENTS_READ",
              },
              component: Payments,
            },
            {
              name: "payouts",
              path: "payouts",
              meta: {
                label: "Virement depuis Stripe",
                requiredPermission: "PAYOUTS_READ",
              },
              component: Payouts,
            },
            {
              name: "unpaid",
              path: "unpaid",
              meta: {
                label: "Impayés",
                requiredPermission: "UNPAID_READ",
              },
              component: Unpaid,
            },
          ],
        },
        {
          path: "attributions",
          name: "attributions",
          meta: {
            label: "Attributions",
            requiredPermission: "ATTRIBUTIONS_READ",
          },
          component: Attributions,
          children: [
            {
              path: "client/:id",
              component: AttributionsClient,
              name: "attributions-client",
              meta: {
                label: "Client",
                requiredPermission: "ATTRIBUTIONS_READ",
              },
            },
            {
              path: "order/:id",
              component: AttributionsOrder,
              name: "attributions-order",
              meta: {
                label: "Commande",
                requiredPermission: "ATTRIBUTIONS_READ",
              },
            },
            {
              path: "session/:id",
              component: AttributionsSession,
              name: "attributions-session",
              meta: {
                label: "Séance",
                requiredPermission: "ATTRIBUTIONS_READ",
              },
            },
          ],
        },
        {
          path: "clients",
          name: "clientsNav",
          meta: {
            label: "Prospects - Clients",
            requiredPermission: "CLIENTS_READ",
          },
          component: ClientsNav,
          children: [
            {
              path: "list",
              name: "list",
              meta: {
                label: "Liste",
                requiredPermission: "CLIENTS_READ",
              },
              component: Clients,
              children: [
                {
                  path: "prospects",
                  name: "prospects",
                  meta: {
                    label: "Prospects",
                    requiredPermission: "CLIENTS_READ",
                  },
                  component: ProspectTab,
                },
                {
                  path: "clients",
                  name: "clients",
                  meta: {
                    label: "Clients",
                    requiredPermission: "CLIENTS_READ",
                  },
                  component: ClientTab,
                },
              ],
            },
            {
              name: "clientSubstitutions",
              path: "substitutions",
              meta: {
                label: "Remplacements",
                requiredPermission: "SUBSTITUTIONS_READ",
              },
              component: ClientsSubstitutions,
            },
          ],
        },
        {
          path: "clients/:id",
          name: "client-details",
          component: ClientsDetails,
          meta: {
            label: "Fiche client",
            requiredPermission: "CLIENTS_READ",
          },
        },
        // {
        //   name: "clientsGroups",
        //   path: "groups",
        //   meta: {
        //     label: "Groupes",
        //     requiredPermission: "GROUP_MENU_READ",
        //   },
        //   component: ClientsGroups,
        //   children: [
        //     {
        //       name: "clientsGroup-details",
        //       path: ":id",
        //       meta: {
        //         label: "Détails du groupe",
        //         requiredPermission: "GROUP_MENU_READ",
        //       },
        //       component: ClientsGroupDetails,
        //     },
        //   ],
        // },
        {
          path: "users",
          name: "usersNav",
          meta: {
            label: "Prestataires",
            requiredPermission: "USERS_MENU_READ",
          },
          component: UsersNav,
          children: [
            {
              path: "users",
              name: "users",
              meta: {
                label: "Prestataires",
                requiredPermission: "USERS_READ",
              },
              component: Users,
            },
            {
              name: "documents",
              path: "documents",
              meta: {
                label: "Documents",
                requiredPermission: "DOCUMENTS_READ",
              },
              component: Documents,
            },
            {
              name: "disbursements",
              path: "disbursements",
              meta: {
                label: "Facturation Coach",
                requiredPermission: "DISBURSEMENTS_READ",
              },
              component: Disbursements,
              children: [
                {
                  name: "pending-disbursements",
                  path: "pending-disbursements",
                  meta: {
                    label: "En attente",
                    requiredPermission: "DISBURSEMENTS_READ",
                  },
                  component: DisbursementsPending,
                },
                {
                  name: "processed-disbursements",
                  path: "processed-disbursements",
                  meta: {
                    label: "Traités",
                    requiredPermission: "DISBURSEMENTS_READ",
                  },
                  component: DisbursementsProcessed,
                },
                {
                  name: "payed-disbursements",
                  path: "payed-disbursements",
                  meta: {
                    label: "Payés",
                    requiredPermission: "DISBURSEMENTS_READ",
                  },
                  component: DisbursementsPayed,
                },
                {
                  name: "blocked-disbursements",
                  path: "blocked-disbursements",
                  meta: {
                    label: "Bloqués",
                    requiredPermission: "DISBURSEMENTS_READ",
                  },
                  component: DisbursementsBlocked,
                },
                {
                  name: "disbursements-troubles",
                  path: "disbursements-troubles",
                  meta: {
                    label: "Contestations",
                    requiredPermission: "DISBURSEMENTS_READ",
                  },
                  component: DisbursementsTroubles,
                },
              ],
            },
            {
              name: "substitutions",
              path: "substitutions",
              meta: {
                label: "Remplacements",
                requiredPermission: "SUBSTITUTIONS_READ",
              },
              component: Substitutions,
            },
            {
              path: "coachs-pictures",
              name: "coachs-pictures",
              meta: {
                label: "Photos",
                requiredPermission: "COACHS_PICTURES_READ",
              },
              component: UsersProfilePictures,
            },
          ],
        },
        {
          path: "b2b",
          name: "b2b",
          meta: {
            label: "B2B",
            requiredPermission: "COMPANIES_MENU_READ",
          },
          component: CompaniesNav,
          children: [
            {
              path: "companies",
              name: "companies-list",
              meta: {
                label: "Entreprises",
                requiredPermission: "COMPANIES_COMPANY_READ",
              },
              component: CompaniesList,
              children: [
                {
                  path: ":id",
                  name: "company-details",
                  meta: {
                    label: "Détails de l'entreprise",
                    requiredPermission: "COMPANIES_COMPANY_READ",
                  },
                  component: CompanyDetails,
                },
              ],
            },
            {
              path: "public-procurements",
              name: "public-procurements-list",
              meta: {
                label: "Marchés publics",
                requiredPermission: "PUBLIC_PROCUREMENTS_READ",
              },
              component: PublicProcurementsList,
              children: [
                {
                  path: ":id",
                  name: "public-procurement-details",
                  meta: {
                    label: "Détails du marché public",
                    requiredPermission: "PUBLIC_PROCUREMENTS_READ",
                  },
                  component: CompanyDetails,
                },
              ],
            },
          ],
        },
        {
          path: "users/:id",
          component: UsersDetails,
          name: "user-details",
          meta: {
            label: "Fiche du prestataire",
            requiredPermission: "USERS_READ",
          },
        },
        {
          name: "fidelisation",
          path: "/fidelisation",
          meta: {
            label: "Fidélisation",
            requiredPermission: "FIDELISATION_READ",
          },
          component: Fidelisation,
        },
        {
          name: "conversion",
          path: "/conversion",
          meta: {
            label: "Conversion",
            requiredPermission: "CONVERSION_READ",
          },
          component: Conversion,
        },
        {
          name: "coach-manager",
          path: "/coach-manager",
          meta: {
            label: "Coach manager",
            requiredPermission: "COACH_MANAGER_READ",
          },
          component: CoachManager,
          children: [
            {
              path: "lost",
              component: CoachManagerLost,
              name: "coach-manager-lost",
              meta: {
                label: "Perte",
                requiredPermission: "COACH_MANAGER_LOST_READ",
              },
            },
            {
              path: "status",
              component: CoachManagerStatus,
              name: "coach-manager-status",
              meta: {
                label: "Status",
                requiredPermission: "COACH_MANAGER_STATUS_READ",
              },
            },
            {
              path: "conversion",
              component: CoachManagerConversion,
              name: "coach-manager-conversion",
              meta: {
                label: "Conversion",
                requiredPermission: "COACH_MANAGER_CONVERSION_READ",
              },
            },
            {
              path: "fidelisation",
              component: CoachManagerFidelisation,
              name: "coach-manager-fidelisation",
              meta: {
                label: "Fidelisation",
                requiredPermission: "COACH_MANAGER_FIDELISATION_READ",
              },
            },
            {
              path: "scrapping",
              component: CoachManagerScrapping,
              name: "coach-manager-scrapping",
              meta: {
                label: "Scrapping",
                requiredPermission: "COACH_MANAGER_SCRAPPING_READ",
              },
            },
            {
              path: "internal-note",
              component: CoachManagerInternalNote,
              name: "coach-manager-internal-note",
              meta: {
                label: "Notes interne",
                requiredPermission: "COACH_MANAGER_INTERNAL_NOTES_READ",
              },
            },
            {
              path: "clients-notes",
              component: CoachManagerClientsNote,
              name: "coach-manager-clients-notes",
              meta: {
                label: "Notes clients",
                requiredPermission: "COACH_MANAGER_CLIENTS_NOTES_READ",
              },
            },
          ],
        },
        {
          name: "live",
          path: "/live",
          meta: {
            label: "Live",
            requiredPermission: "LIVE_MENU_READ",
          },
          component: Live,
          children: [
            {
              path: "live-origins",
              component: Origins,
              name: "live-origins",
              meta: {
                label: 'Type de Cours - Lives "Origine"',
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "lives",
              component: LiveCourses,
              name: "lives",
              meta: {
                label: "Programmation Cours - Lives",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "calendar",
              component: LiveCalendar,
              name: "live-calendar",
              meta: {
                label: "Calendrier",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "sponsorship",
              component: SettingsSponsorship,
              name: "sponsorship",
              meta: {
                label: "Parrainage Live",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "invitations",
              component: SettingsInvitationCode,
              name: "invitations",
              meta: {
                label: "Codes d'invitations",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "replays",
              component: LiveReplays,
              name: "live-replays",
              meta: {
                label: "Replays",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "stats",
              component: LiveStatistics,
              name: "stats",
              meta: {
                label: "Statistiques",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "clients",
              component: LiveClients,
              name: "clients",
              meta: {
                label: "Clients",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "suggestions",
              component: Suggestions,
              name: "suggestions",
              meta: {
                label: "Suggestions",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
            {
              path: "global-invoice",
              component: LiveGlobalInvoice,
              name: "global-invoice",
              meta: {
                label: "Facture globale Live",
                requiredPermission: "LIVE_MENU_READ",
              },
            },
          ],
        },
        {
          name: "settings",
          path: "/settings",
          meta: {
            label: "Paramètres",
            requiredPermission: "SETTINGS_READ",
          },
          component: Settings,
          children: [
            {
              path: "promo-codes",
              component: SettingsPromoCodes,
              name: "promo-codes",
              meta: {
                label: "Codes Promo",
                requiredPermission: "SETTINGS_PROMO_CODE_READ",
              },
            },
            {
              path: "concepts",
              name: "concepts",
              meta: {
                label: "Concepts",
                requiredPermission: "SETTINGS_CONCEPT_READ",
              },
              component: SettingsConcepts,
              children: [
                {
                  path: ":id",
                  component: SettingsConceptsDetails,
                  name: "concept-details",
                  meta: {
                    label: "Modification",
                    requiredPermission: "SETTINGS_CONCEPT_READ",
                  },
                },
              ],
            },
            {
              path: "teammembers",
              component: SettingsTeamMembers,
              name: "teammembers",
              meta: {
                label: "Membres de l'équipe",
                requiredPermission: "SETTINGS_TEAM_READ",
              },
              children: [
                {
                  path: ":id",
                  component: SettingsTeamMembersDetails,
                  name: "teammembers-details",
                  meta: {
                    label: "Fiche du membre",
                    requiredPermission: "SETTINGS_TEAM_READ",
                  },
                },
              ],
            },
            {
              path: "configs",
              component: SettingsConfigs,
              name: "configs",
              meta: {
                label: "Configurations",
                requiredPermission: "SETTINGS_CONFIG_READ",
              },
            },
            {
              path: "payment-methods",
              name: "payment-methods",
              meta: {
                label: "Moyens de paiement",
                requiredPermission: "SETTINGS_PAYMENTS_TYPE_READ",
              },
              component: SettingsPaymentMethods,
              children: [
                {
                  path: ":id",
                  component: SettingsPaymentMethodsDetails,
                  name: "payment-method-details",
                  meta: {
                    label: "Modification Moyen de paiement",
                    requiredPermission: "SETTINGS_PAYMENTS_TYPE_READ",
                  },
                },
              ],
            },
            {
              path: "intervenants",
              component: SettingsIntervenants,
              name: "intervenants",
              meta: {
                label: "Prestataires",
                requiredPermission: "SETTINGS_PRESTA_READ",
              },
            },
            {
              path: "merchants",
              name: "merchants",
              meta: {
                label: "Sociétés",
                requiredPermission: "MERCHANTS_READ",
              },
              component: Merchants,
              children: [
                {
                  path: ":id",
                  component: MerchantsDetails,
                  name: "merchant-details",
                  meta: {
                    label: "Modification",
                    requiredPermission: "MERCHANTS_READ",
                  },
                },
              ],
            },
            {
              path: "export",
              component: Export,
              name: "export",
              meta: {
                label: "Export",
                requiredPermission: "EXPORT_READ",
              },
              children: [
                {
                  path: "clients",
                  component: ExportClients,
                  name: "export-clients",
                  meta: {
                    label: "Clients",
                    requiredPermission: "EXPORT_CLIENT_READ",
                  },
                },
                {
                  path: "prospects",
                  component: ExportProspects,
                  name: "export-prospects",
                  meta: {
                    label: "Prospects",
                    requiredPermission: "EXPORT_PROSPECT_READ",
                  },
                },
                {
                  path: "orders",
                  component: ExportOrders,
                  name: "export-orders",
                  meta: {
                    label: "Commandes",
                    requiredPermission: "EXPORT_ORDER_READ",
                  },
                },
                {
                  path: "marketing-form",
                  component: ExportMarketingForm,
                  name: "marketing-form",
                  meta: {
                    label: "Leads X Marketing",
                    requiredPermission: "EXPORT_MARKETING_FORM_READ",
                  },
                },
                {
                  path: "prestataires",
                  component: ExportPrestataires,
                  name: "export-prestataires",
                  meta: {
                    label: "Prestataires",
                    requiredPermission: "EXPORT_COACH_READ",
                  },
                },
                {
                  path: "nova",
                  component: ExportNova,
                  name: "export-nova",
                  meta: {
                    label: "NOVA",
                    requiredPermission: "EXPORT_NOVA",
                  },
                },
                {
                  path: "solde-comptable",
                  component: ExportSoldeComptable,
                  name: "export-solde-comptable",
                  meta: {
                    label: "Balance comptable",
                    requiredPermission: "EXPORT_BALANCE_COMPTABLE_READ",
                  },
                },
                {
                  path: "compta",
                  component: ExportCompta,
                  name: "export-compta",
                  meta: {
                    label: "Export factures et paiements",
                    requiredPermission: "EXPORT_COMPTA_READ",
                  },
                },
              ],
            },
            {
              path: "import",
              component: Import,
              name: "import",
              meta: {
                label: "Import",
                requiredPermission: "IMPORT_WRITE",
              },
              children: [
                {
                  path: "prestataires",
                  component: ImportPrestataires,
                  name: "import-prestataires",
                  meta: {
                    label: "Prestataires",
                    requiredPermission: "IMPORT_WRITE",
                  },
                },
              ],
            },
            {
              path: "dynamic-content",
              name: "dynamicContentNav",
              meta: {
                label: "Contenu pour les applications",
                requiredPermission: "DYNAMIC_CONTENT_READ",
              },
              component: DynamicContentNav,
              children: [
                {
                  path: "news",
                  name: "news",
                  meta: {
                    label: "Actualités",
                    requiredPermission: "DYNAMIC_CONTENT_READ",
                  },
                  component: SettingsDynamicContent,
                },
                {
                  path: "coach-app-notification",
                  name: "coach-app-notification",
                  meta: {
                    label: "Notifications application coach",
                    requiredPermission: "DYNAMIC_CONTENT_READ",
                  },
                  component: SettingsCustomUserEvent,
                },
              ],
            },
            {
              path: "sap",
              name: "sap",
              meta: { label: "SAP" },
              component: SettingsSap,
            },
            {
              path: "faq",
              name: "faq",
              meta: { label: "Questions/Réponses" },
              component: SettingsFaq,
              children: [
                {
                  path: ":id",
                  component: SettingsFaqDetails,
                  name: "faq-details",
                  meta: {
                    label: "Modification",
                    requiredPermission: "FAQ_READ",
                  },
                },
              ],
            },
          ],
        },
        {
          name: "stats",
          path: "/stats",
          meta: {
            label: "Statistiques",
            requiredPermission: "STATS_READ",
          },
          component: Stats,
          children: [
            {
              path: "marketing",
              name: "marketing",
              meta: {
                label: "Marketing",
                requiredPermission: "STATS_MARKETING_READ",
              },
              component: StatsMarketing,
              children: [
                {
                  path: "ca-roas",
                  name: "ca-roas",
                  meta: {
                    label: "CA/ROAS",
                    requiredPermission: "STATS_MARKETING_ROAS_READ",
                  },
                  component: StatsROAS,
                },
                {
                  path: "expense",
                  name: "expense",
                  meta: {
                    label: "Dépenses",
                    requiredPermission: "STATS_EXPENSE_READ",
                  },
                  component: StatsExpense,
                },
                {
                  path: "form-expense",
                  name: "form-expense",
                  meta: {
                    label: "Coûts formulaires",
                    requiredPermission: "STATS_MARKETING_FORM_EXPENSE_READ",
                  },
                  component: StatsFormExpense,
                },
                {
                  path: "kpi",
                  name: "marketing-kpi",
                  meta: {
                    label: "KPI",
                    requiredPermission: "STATS_MARKETING_KPI_READ",
                  },
                  component: StatsMarketingKpi,
                },
              ],
            },
            {
              path: "lost",
              name: "lost",
              meta: {
                label: "Pertes",
                requiredPermission: "STATS_LOST_READ",
              },
              component: StatsLost,
              children: [
                {
                  path: "prospects-clients",
                  name: "lost-prospects-clients",
                  meta: {
                    label: "Prospects - Clients",
                  },
                  component: StatsLostProspectsClients,
                },
                {
                  path: "sources",
                  name: "lost-sources",
                  meta: {
                    label: "Sources",
                  },
                  component: StatsLostSources,
                },
                {
                  path: "map",
                  name: "lost-map",
                  meta: {
                    label: "Carte",
                  },
                  component: StatsLostMap,
                },
              ],
            },
            {
              path: "commercials",
              name: "commercials",
              meta: {
                label: "Commerciaux",
                requiredPermission: "STATS_COMMERCIALS_READ",
              },
              component: StatsCommercials,
              children: [
                {
                  path: "statistique-commercials",
                  name: "statistique-commercials",
                  meta: {
                    label: "Statistique commerciaux",
                    requiredPermission: "STATS_STATS_COMMERCIALS_READ",
                  },
                  component: StatsStatsCommercialsV2,
                },
                {
                  path: "acquisition",
                  name: "acquisition",
                  meta: {
                    label: "Acquisition/Fidélisation",
                    requiredPermission: "STATS_ACQUISITION_READ",
                  },
                  component: StatsAcquisitionFidelisation,
                },
                {
                  path: "cashed-payments",
                  name: "cashed-payments",
                  meta: {
                    label: "Paiements encaissés",
                    requiredPermission: "STATS_PAYMENTS_READ",
                  },
                  component: StatsPayments,
                },
                {
                  path: "attributions-stats",
                  name: "attributions-stats",
                  meta: {
                    label: "Attribution",
                    requiredPermission: "STATS_ATTRIBUTION_READ",
                  },
                  component: StatsAttribution,
                },
              ],
            },
            {
              path: "sponsorships",
              name: "sponsorships",
              meta: {
                label: "Parrainage",
                requiredPermission: "STATS_SPONSORSHIP_READ",
              },
              component: StatsSponsorship,
            },
            {
              path: "live",
              name: "live-stats",
              meta: {
                label: "Live",
                requiredPermission: "STATS_LIVE_READ",
              },
              component: StatsLive,
            },
            {
              path: "ca-ratios",
              name: "stats-ca-ratios",
              meta: {
                label: "CA Ratios",
                requiredPermission: "STATS_CA_RATIOS_READ",
              },
              component: StatsCARatios,
              children: [
                {
                  path: "couts",
                  name: "statistique-ca-ratios-couts",
                  meta: {
                    label: "Coûts",
                    requiredPermission: "STATS_CA_RATIOS_READ",
                  },
                  component: StatsCARatiosCouts,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  try {
    window.gtag("event", "page_view", {
      url: to.path,
    });

    if (to.path === "/") {
      if (!store.state.user) await store.dispatch("loadUser");
      if (router.app.hasPermission(store.state.user, "DASHBOARD_READ"))
        return next({ name: "dashboard" });
      if (router.app.hasPermission(store.state.user, "MYACCOUNT_READ"))
        return next({ name: "account" });
    }

    if (to.meta.requiredPermission) {
      if (!store.state.user) await store.dispatch("loadUser");

      let isAllowed = false;

      if (Array.isArray(to.meta.requiredPermission)) {
        for (let permission of to.meta.requiredPermission) {
          if (router.app.hasPermission(store.state.user, permission)) {
            isAllowed = true;
          }
        }
      } else if (
        router.app.hasPermission(store.state.user, to.meta.requiredPermission)
      ) {
        isAllowed = true;
      }

      if (!isAllowed) {
        if (router.app.hasPermission(store.state.user, "DASHBOARD_READ"))
          return next({ name: "dashboard" });
        if (router.app.hasPermission(store.state.user, "MYACCOUNT_READ"))
          return next({ name: "account" });
        return next(false);
      }
    }

    if (to && to.matched) {
      const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.label);

      if (nearestWithTitle) {
        const parents = [];

        let routeCheckedForParents = nearestWithTitle;

        while (
          routeCheckedForParents.parent &&
          routeCheckedForParents.parent.name !== "Home"
        ) {
          const { parent } = routeCheckedForParents;

          parents.push(parent);

          routeCheckedForParents = parent;
        }

        let parentsString = "";

        if (parents.length > 0) {
          for (const parent of parents.reverse()) {
            if (parent.meta.label) {
              parentsString = parentsString + `${parent.meta.label} / `;
            }
          }
        }

        document.title =
          "Just Coaching - " + parentsString + nearestWithTitle.meta.label;
      }
    }
  } catch (e) {
    return next({ name: "login" });
  }

  next();
});

export default router;
