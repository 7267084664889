<template>
  <div>
    <div class="d-flex justify-content-between my-1">
      <div v-if="totals">
        <span
          v-for="(status, index) in coachStatus"
          :key="index"
          class="badge text-white"
          :style="`padding: 5px; margin-right: 5px; backgroundColor: ${status.color};`"
        >
          {{ totals[status.value] }}
          {{ status.label }}
        </span>
      </div>
      <div v-if="inactive">
        <button class="btn btn-sm btn-secondary" @click="toggleArchived">
          <span v-if="!displayArchived">
            <i class="fa fa-user-times" aria-hidden="true"></i> Voir les
            archives
          </span>
          <span v-else>Fermer les archives</span>
        </button>
      </div>
    </div>

    <el-table
      :data="coachs"
      :default-sort="defaultSort"
      max-height="1300"
      @sort-change="sortChangeHandler"
    >
      <el-table-column
        label="Nom"
        prop="coach.lastName"
        :sortable="customSort"
      ></el-table-column>
      <el-table-column
        label="Prénom"
        prop="coach.firstName"
        :sortable="customSort"
      ></el-table-column>
      <el-table-column
        label="Ville"
        prop="coach.city"
        :sortable="customSort"
      ></el-table-column>
      <el-table-column
        label="CP"
        prop="coach.zipcode"
        align="center"
        :sortable="customSort"
      ></el-table-column>
      <el-table-column label="Fiche" align="center" width="60">
        <template slot-scope="scope">
          <a :href="coachHref(scope.row.id)" target="_blank">
            <i class="icon-user"></i>
          </a>
        </template>
      </el-table-column>
      <el-table-column label="Document" align="center" width="90">
        <template slot-scope="scope">
          <i
            v-if="
              scope.row.documents &&
              getRequiredDocument(scope.row.documents).length === 4
            "
            class="fa fa-file"
            :class="{ 'text-success': scope.row.validated }"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        v-if="displayEnrichedData"
        label="Concept"
        align="center"
      >
        <template slot-scope="scope">
          <span
            v-for="(concept, index) in coachConcepts(scope.row)"
            :key="index"
            v-html="concept"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="displayEnrichedData"
        label="Fréquence"
        align="center"
        width="90"
      >
        <template
          v-if="prestataireClientFrequency(scope.row.clients, scope.row.id)"
          slot-scope="scope"
        >
          {{ prestataireClientFrequency(scope.row.clients, scope.row.id) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Statut"
        prop="coach.status"
        align="center"
        :sortable="customSort"
      >
        <template slot-scope="scope">
          <span
            class="badge text-white"
            :style="`backgroundColor: ${getCoachStatusColor(
              scope.row.coach.status
            )};`"
            >{{ getCoachStatusLabel(scope.row.coach.status) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="Date de création"
        prop="createdAt"
        align="center"
        width="160"
        :sortable="customSort"
      >
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Coach manager"
        prop="refCoachManagerEmail"
        align="center"
        width="220"
        :sortable="customSort"
      >
        <template v-if="scope.row.refCoachManager" slot-scope="scope">
          <div
            v-if="scope.row.refCoachManager.coachManager"
            class="d-flex justify-content-center"
          >
            <div
              class="text-white rounded-3 text-nowrap"
              style="padding: 0 5px"
              :style="
                scope.row.refCoachManager.coachManager.color &&
                `background-color:${scope.row.refCoachManager.coachManager.color}`
              "
              :class="{
                'bg-primary': !scope.row.refCoachManager.coachManager.color,
              }"
            >
              {{
                scope.row.refCoachManager.coachManager.pseudo ||
                scope.row.refCoachManager.email
              }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showDistance"
        label="Distance"
        align="center"
        width="90"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.distance || scope.row.distance === 0">
            {{ scope.row.distance.toFixed(2).replace(".", ",") }} km
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Note" width="220">
        <template slot-scope="scope">
          <NoteModal
            :notes="scope.row.coach.commercialNotes"
            :key="scope.row.id"
            @showComment="$emit('showComment', scope.row)"
            permission="USERS_READ"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="inactive && !displayArchived"
        label="Archiver"
        align="center"
        width="90"
      >
        <template slot-scope="scope">
          <button
            class="btn btn-info"
            @click.stop="$emit('archiveUser', scope.row)"
          >
            <i class="icon icon-briefcase"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <paginate
      v-model="page"
      ref="paginate"
      :page-count="count"
      :click-handler="paginationHandler"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
      class="mt-1"
    ></paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import mixin from "../mixin";
import FrequencyMixin from "../mixin/FrequencyMixin";
import NoteModal from "./form/NoteModal";

import { mapGetters } from "vuex";

export default {
  mixins: [mixin, FrequencyMixin],
  components: {
    Paginate,
    NoteModal,
  },
  props: {
    coachs: {
      type: Array,
      default: () => [],
    },

    totals: {
      type: Object,
      default: null,
    },

    displayEnrichedData: {
      type: Boolean,
      default: false,
    },

    pageProp: {
      type: Number,
      required: true,
    },

    rows: {
      type: Number,
      default: 20,
    },

    showDistance: {
      type: Boolean,
      default: false,
    },

    displayArchived: {
      type: Boolean,
      default: false,
    },

    inactive: {
      type: Boolean,
      default: false,
    },

    searchResults: {
      type: Boolean,
      default: false,
    },

    sortProp: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      customSort: "custom",
      noPagination: false,
      defaultSort: { prop: "createdAt", order: "descending" },
      page: undefined,
    };
  },

  created() {
    this.defaultSort = this.sortProp;

    this.noPagination = this.showDistance || this.searchResults;

    if (this.noPagination) {
      this.customSort = null;
      this.defaultSort = {};
    }
  },

  watch: {
    showDistance(val) {
      if (val) {
        this.noPagination = true;
        this.customSort = "custom";
        this.defaultSort = {};
      } else {
        this.noPagination = false;
        this.customSort = null;
        this.defaultSort = { prop: "createdAt", order: "descending" };
      }
    },

    pageProp(newPage) {
      this.page = newPage;
      this.$refs.paginate.selected = this.page - 1;
    },

    sortProp(newSort) {
      this.defaultSort = newSort;
    },
  },

  computed: {
    ...mapGetters({
      coachStatus: "getCoachStatus",
    }),

    count() {
      if (this.totals) {
        if (this.inactive) {
          return this.displayArchived
            ? Math.ceil(this.totals.archived / this.rows)
            : Math.ceil(this.totals.inactive / this.rows);
        }

        return Math.ceil(this.totals.coachs / this.rows);
      }

      return Math.ceil(this.coachs.length / this.rows);
    },
  },

  methods: {
    toggleArchived() {
      this.$emit("toggleArchived", !this.displayArchived);
    },

    sortChangeHandler(sort) {
      this.$emit("sort", sort);
    },

    coachConcepts(coach) {
      let data = [];

      if (coach.sessions) {
        let concepts = {};

        coach.sessions.forEach((session) => {
          if (!session.order) {
            return;
          }

          concepts[session.order.concept] =
            concepts[session.order.concept] || 0;
          concepts[session.order.concept]++;
        });

        for (let name in concepts) {
          if (Object.prototype.hasOwnProperty.call(concepts, name)) {
            data.push(
              (this.conceptLogo(name, this.concepts) || "") +
                " (" +
                concepts[name] +
                ") "
            );
          }
        }
      }

      return data;
    },

    paginationHandler(page) {
      this.$emit("changePage", page);
    },

    coachHref(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id,
        },
      });

      return route.href;
    },

    getRequiredDocument(documents) {
      return documents.filter((d) =>
        ["Diplome", "Siret", "Contrat", "RIB"].includes(d.tag)
      );
    },

    getCoachStatusLabel(status) {
      const currentCoachStatus = this.coachStatus.find(
        (s) => s.value === status
      );

      return currentCoachStatus?.label;
    },

    getCoachStatusColor(status) {
      const currentCoachStatus = this.coachStatus.find(
        (s) => s.value === status
      );

      return currentCoachStatus?.color;
    },
  },
};
</script>
