<template>
  <modal
    v-if="userHasWritePermission"
    :title="
      form.isPublicProcurement
        ? 'Ajouter un marché public'
        : 'Ajouter une entreprise'
    "
    :value="show"
    effect="fade/zoom"
    cancelText="Fermer"
    okText="Créer"
    @ok="createCompany"
    @cancel="closeModal"
  >
    <Loader :isVisible="loading" />
    <div class="modal-body">
      <form autocomplete="off">
        <div class="form-group">
          <el-switch
            v-model="form.isPublicProcurement"
            @change="toggleIsPublicProcurement"
            active-text="Marché public"
            inactive-text="Entreprise"
            :disabled="disableIsPublicProcurementToggle"
          />
        </div>
        <div class="form-group">
          <label>Nom</label>
          <el-input v-model="form.name" size="mini" />
        </div>
        <div class="alert alert-danger" role="alert" v-if="formError.emptyName">
          Le nom est obligatoire
        </div>

        <div class="form-group">
          <label>Rechercher une fiche client</label>
          <v-select
            v-model="form.client"
            :options="clients"
            @search="searchClients"
          ></v-select>
        </div>
        <div
          class="alert alert-danger"
          role="alert"
          v-if="formError.emptyClient"
        >
          Vous devez choisir un client
        </div>

        <div class="form-group">
          <label>Commercial</label>
          <multiselect
            placeholder="Choisir un commercial"
            v-model="form.commercial"
            :disabled="disableCommercialSelect"
            :options="commercialsOptions"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>

        <div class="form-group">
          <label>Domaines</label>
          <div v-if="form.domains && form.domains.length">
            <span
              v-for="domain in form.domains"
              :key="domain"
              class="mb-1 mr-1 badge badge-primary"
              style="cursor: pointer"
              @click="editDomain(domain)"
            >
              {{ domain }}
              <span class="ml-05" @click.stop="removeDomain(domain)">
                <i class="fa fa-times"></i>
              </span>
            </span>
          </div>
          <template v-if="addDomain.showInput">
            <div class="d-flex mb-1">
              <el-input v-model="addDomain.domainName" size="mini" />
              <button
                class="btn btn-sm btn-primary ml-05"
                @click="addDomainName"
              >
                <i class="fa fa-plus"></i>
              </button>
              <button
                class="btn btn-sm btn-danger ml-05"
                @click="cancelAddDomain"
              >
                <i class="fa fa-times"></i>
              </button>
            </div>
            <div
              class="alert alert-danger mb-1"
              role="alert"
              v-if="addDomain.domainError"
            >
              Domaine invalide
            </div>
          </template>
          <div v-else>
            <button class="btn btn-sm" @click="addDomain.showInput = true">
              <i class="fa fa-plus"></i> Ajouter un domaine
            </button>
          </div>
        </div>

        <div class="form-group">
          <label>Fonctionnalités</label>

          <div
            v-for="feature in features"
            :key="feature.name"
            class="form-group"
          >
            <el-switch
              :value="form.features && form.features.includes(feature.name)"
              size="mini"
              @change="toggleAddFormFeature(feature.name)"
            />
            <label class="ml-05">{{ feature.nicename }}</label>
            <i
              class="fa fa-info-circle ml-05"
              v-tooltip="{ content: feature.description, placement: 'right' }"
            ></i>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import debounce from "lodash.debounce";

import Loader from "../Loader";
import Modal from "vue-strap/src/Modal";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Loader,
    Modal,
    Multiselect,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    commercials: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      form: {
        isPublicProcurement: false,
        name: "",
        client: "",
        commercial: {},
        domains: [],
        features: [],
      },
      formError: {
        emptyName: false,
        emptyClient: false,
      },
      addDomain: {
        showInput: false,
        domainName: "",
        domainError: false,
      },
      commercialsOptions: [],
      disableCommercialSelect: false,
      clients: [],
      features: [],
    };
  },

  computed: {
    userHasWritePermission() {
      return this.$route.name.includes("public-procurement")
        ? this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          )
        : this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE");
    },

    disableIsPublicProcurementToggle() {
      return (
        (this.$route.name.includes("public-procurement") &&
          !this.hasPermission(
            this.$store.state.user,
            "COMPANIES_COMPANY_WRITE"
          )) ||
        (this.$route.name.includes("company") &&
          !this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          ))
      );
    },
  },

  watch: {
    commercials() {
      this.setCommercialSelectOptions(
        this.$route.name.includes("public-procurement")
      );
    },
  },

  created() {
    this.fetchFeatures();
  },

  mounted() {
    this.form.isPublicProcurement =
      this.$route.name.includes("public-procurement");

    this.setCommercialSelectOptions(
      this.$route.name.includes("public-procurement")
    );
  },

  methods: {
    async fetchFeatures() {
      try {
        const { data } = await this.$api.get("/features/company");

        this.features = data;
      } catch (e) {
        this.features = [];
      }
    },

    closeModal() {
      this.$emit("close");

      this.resetForm();
    },

    resetForm() {
      this.form = {
        isPublicProcurement: false,
        name: "",
        client: "",
        commercial: {},
        domains: [],
        features: [],
      };

      if (this.$route.name.includes("public-procurement")) {
        this.form.isPublicProcurement = true;

        const publicProcurementsCommercial = this.commercials.find(
          (c) => c.value === "marche@justcoaching.fr"
        );

        if (publicProcurementsCommercial) {
          this.form.commercial = publicProcurementsCommercial;
          this.disableCommercialSelect = true;
        }
      }

      this.formError = {
        emptyName: false,
        emptyClient: false,
      };

      this.addDomain = {
        showInput: false,
        domainName: "",
        domainError: false,
      };

      this.clients = [];
    },

    async createCompany() {
      if (this.checkFormErrors() === true) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$api.post("/companies", {
          isPublicProcurement: this.form.isPublicProcurement,
          name: this.form.name,
          commercialEmail: this.form.commercial.value,
          clientEmail: this.form.client.value,
          domains: this.form.domains,
          features: this.form.features,
        });

        const route = this.$router.resolve({
          name: this.form.isPublicProcurement
            ? "public-procurement-details"
            : "company-details",
          params: {
            id: data.id,
          },
        });

        window.open(route.href, "_self");
      } catch (e) {
        this.$errorToast(
          this.form.isPublicProcurement
            ? "Impossible de créer le marché public"
            : "Impossible de créer l'entreprise"
        );
      } finally {
        this.loading = false;
      }
    },

    checkFormErrors() {
      const errors = [];

      this.formError.emptyName = false;
      this.formError.emptyClient = false;

      if (!this.form.name) {
        this.formError.emptyName = true;
        errors.push("name");
      }

      if (!this.form.client) {
        this.formError.emptyClient = true;
        errors.push("client");
      }

      return errors.length > 0;
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter`, {
        params: {
          q: search,
        },
      });

      vm.clients = data.map((c) => ({
        label: `${c.company ? "[" + c.company + "]" : ""} ${c.name} (${
          c.email
        })`,
        value: c.email,
        usedEmail: c.usedEmail,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    addDomainName() {
      this.addDomain.domainError = false;

      if (!this.addDomain.domainName || !this.addDomain.domainName.trim()) {
        this.addDomain.domainError = true;

        return;
      }

      const domain = this.addDomain.domainName.trim();

      if (
        domain.length <= 3 ||
        !domain.includes(".") ||
        domain.endsWith(".") ||
        (domain.match(/\W/g) &&
          !domain.match(/\W/g).every((char) => char === "." || char === "-"))
      ) {
        this.addDomain.domainError = true;

        return;
      }

      if (this.form.domains.includes(domain)) {
        this.addDomain.domainError = true;

        return;
      }

      this.form.domains.push(domain);
      this.addDomain.domainName = "";
      this.addDomain.showInput = false;
    },

    cancelAddDomain() {
      this.addDomain.showInput = false;
      this.addDomain.domainError = false;
      this.addDomain.domainName = "";
    },

    editDomain(domain) {
      this.form.domains = this.form.domains.filter((d) => d !== domain);
      this.addDomain.domainName = domain;
      this.addDomain.showInput = true;
    },

    removeDomain(domain) {
      this.form.domains = this.form.domains.filter((d) => d !== domain);
    },

    toggleAddFormFeature(feature) {
      if (this.form.features.includes(feature)) {
        this.form.features = this.form.features.filter((f) => f !== feature);
      } else {
        this.form.features.push(feature);
      }
    },

    setCommercialSelectOptions(isPublicProcurement) {
      this.commercialsOptions = [...this.commercials];
      this.disableCommercialSelect = false;

      if (isPublicProcurement) {
        const publicProcurementsCommercial = this.commercials.find(
          (c) => c.value === "marche@justcoaching.fr"
        );

        if (publicProcurementsCommercial) {
          this.form.commercial = publicProcurementsCommercial;
          this.disableCommercialSelect = true;
        }
      } else {
        this.commercialsOptions = [
          ...this.commercials.filter(
            (c) => c.value !== "marche@justcoaching.fr"
          ),
        ];

        this.form.commercial = {};
      }
    },

    toggleIsPublicProcurement() {
      this.setCommercialSelectOptions(this.form.isPublicProcurement);
    },
  },
};
</script>
