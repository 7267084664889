<template>
  <div :class="card ? 'card' : ''">
    <div :class="`${card ? 'card-header' : ''} d-flex justify-content-between`">
      <div :class="card ? '' : 'font-weight-bold'">
        <i v-if="card" class="fa fa-at mr-05"></i> Commercial
      </div>
      <el-select
        v-if="userHasWritePermission"
        value=""
        size="mini"
        @change="addCommercial($event)"
      >
        <el-option
          :value="commercial.value"
          v-for="commercial in available"
          :key="commercial.value"
          >{{ commercial.pseudo }}</el-option
        >
      </el-select>
    </div>
    <div
      v-if="related.length > 0"
      :class="card ? 'card-block' : ''"
      :style="card ? '' : 'padding: 0.5rem 0'"
    >
      <div
        v-for="(commercial, index) in related"
        :key="`commercial-${commercial.value}`"
        :class="`d-flex justify-content-between ${
          index === 0 ? 'my-1' : 'mb-1'
        }`"
      >
        <span
          class="text-white rounded-3 mr-1 d-flex align-items-center"
          style="padding: 7px"
          :style="commercial.color && `background-color:${commercial.color}`"
          :class="!commercial.color && 'bg-primary'"
        >
          <span>{{ commercial.pseudo }}</span>
          <span
            v-if="userHasWritePermission"
            class="ml-1"
            style="cursor: pointer"
            @click="confirmRemoveCommercial(commercial.value)"
            ><i class="icon-close"></i
          ></span>
        </span>

        <datepicker
          v-if="userHasWritePermission"
          v-model="commercial.relatedSince"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
          @input="newCommercialRelationDate($event, commercial)"
        />
        <span v-else>Ajouté le {{ formatDate(commercial.relatedSince) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  data() {
    return {
      commercials: [],
      related: [],
    };
  },

  props: {
    company: {
      type: Object,
      required: true,
    },

    card: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getCommercials();

    this.related = this.company.commercials || [];
  },

  computed: {
    available() {
      return this.commercials.filter(
        ({ value }) => !this.related.some((com) => com.value === value)
      );
    },

    isPublicProcurementPage() {
      return this.$route.name === "public-procurement-details";
    },

    userHasWritePermission() {
      return this.isPublicProcurementPage
        ? this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          )
        : this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE");
    },
  },

  methods: {
    async getCommercials() {
      try {
        let fetchedCommercials = [];

        const { data: b2bCommercials } = await this.$api.get(
          "/users/commercials/search",
          {
            params: {
              b2b: true,
            },
          }
        );

        fetchedCommercials = b2bCommercials;

        if (this.isPublicProcurementPage) {
          const { data: publicProcurementsCommercials } = await this.$api.get(
            "/users/commercials/search",
            {
              params: {
                commercials: ["marche@justcoaching.fr"],
              },
            }
          );

          fetchedCommercials = [
            ...fetchedCommercials,
            ...publicProcurementsCommercials,
          ];
        }

        this.commercials = fetchedCommercials.map((user) => ({
          pseudo: user.commercial.pseudo,
          value: user.email,
          mobile: user.commercial.mobile,
          color: user.commercial.color,
        }));
      } catch (e) {
        this.commercials = [];
      }
    },

    async addCommercial(event) {
      try {
        const dataset = {
          id: this.company.id,
          commercial: event,
        };

        await this.$api.post("/companies/commercial", dataset);

        const currentCommercial = this.commercials.find(
          ({ value }) => value === event
        );

        this.related.push({
          ...currentCommercial,
          relatedSince: moment().startOf("day").toDate(),
        });
      } catch (e) {
        this.$errorToast(
          "Impossible d'ajouter le commercial, recharger la page pour vérifier qu'il n'est pas déjà attribué."
        );
      }
    },

    confirmRemoveCommercial(commercial) {
      this.$confirm({
        message: "Supprimer le lien commercial ?",
        onConfirm: () => {
          this.removeCommercial(commercial);
        },
      });
    },

    async removeCommercial(commercial) {
      try {
        await this.$api.delete("/companies/commercial", {
          data: {
            id: this.company.id,
            commercial,
          },
        });

        const currentCommercial = this.commercials.find(
          ({ value }) => value === commercial
        );

        this.related = this.related.filter(
          ({ value }) => value !== currentCommercial.value
        );
      } catch (e) {
        this.$errorToast("Impossible de supprimer le commercial.");
      }
    },

    async newCommercialRelationDate(relatedSince, commercial) {
      const data = {
        id: this.company.id,
        commercial: commercial.value,
        relatedSince,
      };

      await this.$api.put(`/companies/commercial`, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.commercial-relation {
  .vdp-datepicker__calendar {
    right: 0;
  }
}
</style>
