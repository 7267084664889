<template>
  <div class="wrapper">
    <router-view></router-view>
    <loader :isVisible="isLoading" />
    <div
      class="animated fadeIn"
      v-if="['companies-list'].indexOf($route.name) > -1"
    >
      <CompaniesListFilters
        @apply-filters="applyFilters"
        :commercials="commercials"
      />

      <div class="card">
        <div class="card-header">
          <span><i class="icon-people mr-05"></i> Entreprises</span>
          <button
            v-if="hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')"
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="showCreateModal = true"
          >
            <i class="icon-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block display-tab">
          <el-table
            :data="companies"
            :default-sort="{ prop: 'name', order: 'ascending' }"
            @row-click="goToCompany"
          >
            <el-table-column type="index" label="#" fixed sortable>
              <template slot-scope="scope">
                {{ offset + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="Nom" prop="name" sortable min-width="200">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="Statut" width="180">
              <template slot-scope="scope">
                <el-select
                  :value="scope.row.statut"
                  size="mini"
                  @change="openStatusConfirm($event, scope.row.id)"
                  :disabled="
                    !hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')
                  "
                  class="w-100"
                  style="border-radius: 5px"
                  :class="`status-color__${scope.row.statut}`"
                >
                  <el-option
                    v-for="status in companyStatuts"
                    :key="status.label"
                    :value="status.value"
                    :label="status.label"
                    :class="`status-color__${status.value}`"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Clients" align="center">
              <template slot-scope="scope">
                <a href="#" @click.stop="showCompanyClients = scope.row">
                  <i class="fa fa-user"></i> {{ scope.row.clients.length }}</a
                >
              </template>
            </el-table-column>
            <el-table-column label="Note" width="250">
              <template slot-scope="scope">
                <NoteModal
                  style="margin-top: 1px"
                  :notes="scope.row.note"
                  :key="scope.row.note"
                  v-if="
                    hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')
                  "
                  @showComment="companyEditNote = { ...scope.row }"
                />
                <span v-else>
                  {{ scope.row.note }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="Commercial" align="center" min-width="180">
              <template slot-scope="scope">
                <template v-for="(rel, index) of scope.row.commercials">
                  <CommercialRel :key="`com-${index}`" :relation="rel" />
                </template>
              </template>
            </el-table-column>

            <el-table-column
              align="right"
              v-if="isAdmin($store.state.user)"
              width="100"
            >
              <template slot-scope="scope">
                <button
                  class="btn btn-danger btn-sm"
                  @click.stop="deleteCompany(scope.row.id)"
                >
                  <i class="fa fa-trash-o"></i>
                </button>
              </template>
            </el-table-column>
          </el-table>

          <paginate
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="handleCurrentPageChange"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
            style="padding: 1.25rem 0"
          ></paginate>
        </div>
      </div>
    </div>

    <CompanyCreateModal
      :show="showCreateModal"
      :commercials="commercials"
      @close="showCreateModal = false"
    />
    <CompanyStatusModal
      :statusConfirmModal="statusConfirmModal"
      @update="editCompanyStatut"
    />
    <CompanyClientsModal
      :company="showCompanyClients"
      @close="showCompanyClients = null"
    />
    <modal
      title="Note"
      :value="!!companyEditNote"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateNote"
      @cancel="companyEditNote = null"
      effect="fade/zoom"
    >
      <textarea
        v-if="companyEditNote"
        v-model="companyEditNote.note"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import Loader from "../components/Loader";
import { mapState, mapGetters } from "vuex";
import Modal from "vue-strap/src/Modal";
import CommercialRel from "../components/CommercialRel";
import CompaniesListFilters from "../components/CompaniesListFilters";
import NoteModal from "../components/form/NoteModal";
import CompanyStatusModal from "../components/company/CompanyStatusModal";
import CompanyCreateModal from "../components/company/CompanyCreateModal";
import CompanyClientsModal from "../components/company/CompanyClientsModal";

export default {
  components: {
    Loader,
    Paginate,
    Modal,
    CompaniesListFilters,
    CommercialRel,
    NoteModal,
    CompanyStatusModal,
    CompanyCreateModal,
    CompanyClientsModal,
  },

  data() {
    return {
      isLoading: false,
      currentPage: 1,
      limit: 15,
      companies: [],
      features: [],
      totals: 0,
      filters: {
        statuts: [],
        companyName: "",
        commercials: [],
      },
      commercials: [],
      showCreateModal: false,
      showCompanyClients: null,
      statusConfirmModal: {
        show: false,
        statut: null,
        companyId: null,
      },
      companyEditNote: null,
    };
  },

  created() {
    this.loadCompanies();
    this.loadCommercials();
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),

    ...mapGetters({
      companyStatuts: "getCompanyStatuts",
    }),

    pageCount() {
      if (!this.totals) {
        return 0;
      }

      return Math.ceil(this.totals / this.limit);
    },

    offset() {
      return (this.currentPage - 1) * this.limit;
    },
  },

  methods: {
    async loadCompanies() {
      try {
        const { data } = await this.$api.get("/companies", {
          params: {
            limit: this.limit,
            offset: this.offset,
            isPublicProcurement: false,
            ...this.filters,
          },
        });

        this.companies = data.data;
        this.totals = data.totals;
        this.isLoading = false;
      } catch (e) {
        this.loadingError = "Impossible de charger les acquisitions";
      } finally {
        this.isLoading = false;
      }
    },

    handleCurrentPageChange(val) {
      this.currentPage = val;

      this.loadCompanies();
    },

    goToCompany({ id }) {
      const route = this.$router.resolve({
        name: "company-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    applyFilters(filters) {
      this.filters = filters;
      this.currentPage = 1;
      this.loadCompanies();
    },

    async loadCommercials() {
      try {
        const { data: b2bCommercials } = await this.$api.get(
          "/users/commercials/search",
          {
            params: { b2b: true },
          }
        );

        const { data: publicProcurementsCommercials } = await this.$api.get(
          "/users/commercials/search",
          {
            params: { commercials: ["marche@justcoaching.fr"] },
          }
        );

        this.commercials = [
          ...b2bCommercials,
          ...publicProcurementsCommercials,
        ].map((c) => ({
          value: c.email,
          label: c.commercial.pseudo,
        }));
      } catch (e) {
        this.commercials = [];
      }
    },

    openStatusConfirm(value, companyId) {
      if (value) {
        this.statusConfirmModal.show = true;
        this.statusConfirmModal.statut = value;
        this.statusConfirmModal.companyId = companyId;
      }
    },

    async editCompanyStatut({ statut, lostReason }) {
      if (lostReason && lostReason.value) {
        lostReason = lostReason.value;
      }

      await this.$api.put(`/companies/${this.statusConfirmModal.companyId}`, {
        statut,
        lostReason,
      });

      this.companies = this.companies.map((company) => {
        if (company.id === this.statusConfirmModal.companyId) {
          company.statut = statut;
        }

        return company;
      });
    },

    async updateNote() {
      if (!this.companyEditNote) {
        return;
      }

      try {
        const { id, note } = this.companyEditNote;

        await this.$api.put(`/companies/${id}`, {
          note: note.trim(),
        });

        this.companies = this.companies.map((company) => {
          if (company.id === id) {
            company.note = note.trim();
          }

          return company;
        });

        this.companyEditNote = null;
      } catch (e) {
        this.$errorToast("Impossible de modifier la note");
      }
    },

    async deleteCompany(id) {
      this.$confirm({
        message:
          "Voulez-vous vraiment supprimer cette entreprise ? Cette action est irréversible.",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/companies/${id}`);

            this.loadCompanies();
          } catch (e) {
            this.$errorToast("Impossible de supprimer l'entreprise");
          }
        },
      });
    },

    addDomainName() {
      this.addModal.addDomain.domainError = false;

      if (
        !this.addModal.addDomain.domainName ||
        !this.addModal.addDomain.domainName.trim()
      ) {
        this.addModal.addDomain.domainError = true;

        return;
      }

      const domain = this.addModal.addDomain.domainName.trim();

      if (
        domain.length <= 3 ||
        !domain.includes(".") ||
        domain.endsWith(".") ||
        (domain.match(/\W/g) &&
          !domain.match(/\W/g).every((char) => char === "." || char === "-"))
      ) {
        this.addModal.addDomain.domainError = true;

        return;
      }

      if (this.addModal.form.domains.includes(domain)) {
        this.addModal.addDomain.domainError = true;

        return;
      }

      this.addModal.form.domains.push(domain);
      this.addModal.addDomain.domainName = "";
      this.addModal.addDomain.showInput = false;
    },

    cancelAddDomain() {
      this.addModal.addDomain.showInput = false;
      this.addModal.addDomain.domainError = false;
      this.addModal.addDomain.domainName = "";
    },

    toggleAddFormFeature(feature) {
      if (this.addModal.form.features.includes(feature)) {
        this.addModal.form.features = this.addModal.form.features.filter(
          (f) => f !== feature
        );
      } else {
        this.addModal.form.features.push(feature);
      }
    },

    editDomain(domain) {
      this.addModal.form.domains = this.addModal.form.domains.filter(
        (d) => d !== domain
      );
      this.addModal.addDomain.domainName = domain;
      this.addModal.addDomain.showInput = true;
    },

    removeDomain(domain) {
      this.addModal.form.domains = this.addModal.form.domains.filter(
        (d) => d !== domain
      );
    },
  },
};
</script>
<style type="text/css" scoped>
.checkboxes {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
}

.type {
  display: flex;
  align-items: center;
}

.type label {
  margin: 0;
}

.type input {
  height: 14px;
  width: 30px;
}
</style>
